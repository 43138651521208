<template>
  <svg
    class="iplay"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <mask
        id="mask0_7671_4617"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="10"
        y="10"
        width="44"
        height="44"
      >
        <rect
          x="10"
          y="10"
          width="44"
          height="44"
          fill="#D9D9D9"
          style="
            fill: #d9d9d9;
            fill: color(display-p3 0.851 0.851 0.851);
            fill-opacity: 1;
          "
        />
      </mask>
      <g mask="url(#mask0_7671_4617)">
        <circle
          cx="38.3071"
          cy="26.5"
          r="11"
          stroke="white"
          style="stroke: white; stroke-opacity: 1"
          stroke-width="4"
        />
        <path
          d="M27.0891 29.1152L16.7309 42.9383C15.7484 44.2494 15.8791 46.0833 17.0376 47.2418V47.2418C18.1961 48.4003 20.03 48.531 21.3411 47.5485L35.1642 37.1903"
          stroke="white"
          style="stroke: white; stroke-opacity: 1"
          stroke-width="4"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  // 白色
  name: 'iplayWIcon',
}
</script>

<style lang="stylus" scoped>
.iplay
  width 72px
  height 72px
</style>
