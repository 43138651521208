<template>
  <svg
    class="iplay"
    width="72"
    height="73"
    viewBox="0 0 72 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_332_8257"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="11"
      y="11"
      width="50"
      height="51"
    >
      <rect x="11.25" y="11.75" width="49.5" height="49.5" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_332_8257)">
      <circle
        cx="43.0957"
        cy="30.3125"
        r="12.9375"
        stroke="#5500C3"
        stroke-width="3.375"
      />
      <path
        d="M30.4746 33.2539L18.8216 48.8049C17.7163 50.2799 17.8634 52.343 19.1667 53.6463V53.6463C20.47 54.9496 22.5331 55.0966 24.0081 53.9914L39.559 42.3383"
        stroke="#5500C3"
        stroke-width="3.375"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IplayIcon',
}
</script>

<style lang="stylus" scoped>
.iplay
  width 72px
  height 72px
</style>
