<!-- _ab: `${config.abTest}-${store.state.abTest.abTestVersionTag}` -->

<template>
  <CommonModal ref="root" class="root-c">
    <!-- 过期会员运营弹窗 -->
    <div class="activity-modal-vip">
      <div @click="handleCloseModal" class="activity-modal-vip-close"></div>
      <!-- <div class="activity-modal-vip-bottom"> -->
      <div class="activity-modal-vip-code">
        <img :src="qrCodeURL" alt="" />
        <!-- <span>微信扫码 立享优惠</span> -->
      </div>
      <!-- </div> -->
    </div>
  </CommonModal>
</template>
<script>
import CommonModal from '@/components/modal/common/component.vue'
import store from '@/store'
import { computed, onBeforeMount, ref } from 'vue'
// import { format } from 'date-fns'
import useQRCode from '@/composables/useQRCode'
import { getCarplayInfo } from '@/service/carplay-info'
import Toast from '@/utils/toast'
import get from 'lodash/get'
// import { sendLog } from '@/directives/v-log/log'
import { payLogFrom } from '@/log/pay'

export default {
  name: 'VipExpireModal',
  components: {
    CommonModal,
  },
  props: {
    closeEvent: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const root = ref(null)
    // const appStartTime = computed(() => store.state.appStartTime)
    const userType = computed(() => store.state.userInfo.userType)

    const { getQRCodeURL } = useQRCode()
    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const getVipQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const qrCodeData = await getQRCodeURL(
          `${data.pay_qr}&log=${payLogFrom.get('会员过期后弹窗').m}&ut=${
            userType.value
          }`
        )
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    const handleCloseModal = () => {
      // const now = Date.now()
      props.closeEvent.call()
      root.value.hide()
    }

    onBeforeMount(getVipQrcode)

    return {
      root,
      qrCodeURL,
      handleCloseModal,
    }
  },
}
</script>
<style lang="stylus" scoped>
.activity-modal-vip
  // padding-top 122px
  padding-top 34px
  position relative
  // width 1000px
  // height 730px
  // background url('https://qncweb.ktvsky.com/20230914/vadd/8787d3067ba5cbf24d5f1ee8b571155b.png') no-repeat
  width 820px
  height 852px
  margin-top -200px
  background url('https://qncweb.ktvsky.com/20241212/vadd/34050e15004fe534046a3290aac435c3.png') no-repeat
  background-size 100% 100%
  background-position center
  color rgba(255, 51, 100, 1)
  display flex
  flex-direction column
  align-items center
  &-close
    // width 90px
    // height 90px
    width 40px
    height 40px
    position absolute
    top 308px
    // left 30px
    right 31px
  // &-bottom
  //   width 100%
  //   height 193px
  //   display flex
  //   justify-content center
  //   position absolute
  //   bottom 40px
  //   left 0

  &-code
    width 192px
    height 192px
    position absolute
    right 130px
    bottom 86px
    img
      width 100%
      height 100%
    // width 162px
    // height 193px
    // background #fff
    // border-radius 8px
    // display flex
    // flex-direction column
    // align-items center
    // img
    //   width 150px
    //   height 150px
    //   margin-top 6px
    // span
    //   height 21px
    //   line-height 21px
    //   font-weight 400
    //   font-size 15px
    //   color rgba(0, 0, 0, 0.8)
    //   margin-top 8px
.root-c
  z-index 999 !important
</style>
