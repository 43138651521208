import useTrySingVipQrcode from './create.js';
import TrySingVipQrcodeComponent from './m.vue';

const Plugin = (app, props = {}, slots = {}) => {
  const TrySingVipQrcode = useTrySingVipQrcode(props, slots);
  app.config.globalProperties.$TrySingVipQrcode = TrySingVipQrcode;
  app.provide('$trySingVipQrcode', TrySingVipQrcode)
};

TrySingVipQrcodeComponent.install = Plugin;

export default TrySingVipQrcodeComponent;
