<template>
  <img class="full-screen-vip-bg" :src="TRYSING_END_BG" alt="" />
</template>

<script>
import IMAGE_URLS from '@/constants/images'
import { ref } from 'vue'

export default {
  name: 'FullScreenVipBg',

  setup() {
    const TRYSING_END_BG = ref(IMAGE_URLS.VIP_MODAL.TRYSING_END_BG)

    return {
      TRYSING_END_BG,
    }
  },
}
</script>

<style lang="stylus" scoped>
.full-screen-vip-bg
  width 100vw !important
  height 100vh !important
</style>
