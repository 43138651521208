<template>
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7">
      <rect width="70" height="70" rx="35" fill="white" fill-opacity="0.4" />
      <rect
        x="42.071"
        y="25.1005"
        width="4"
        height="24"
        rx="2"
        transform="rotate(45 42.071 25.1005)"
        fill="white"
      />
      <rect
        x="44.8994"
        y="42.0711"
        width="4"
        height="24"
        rx="2"
        transform="rotate(135 44.8994 42.0711)"
        fill="white"
      />
    </g>
  </svg>
</template>

<script>
// import IMAGE_URLS from '@/constants/images'

export default {
  name: 'FullScreenVipBg',

  setup() {
    // const TRYSING_END_BG = ref(IMAGE_URLS.VIP_MODAL.TRYSING_END_BG)

    return {
      // TRYSING_END_BG,
    }
  },
}
</script>

<style lang="stylus" scoped>
.full-screen-vip-bg
  width 70px
  height 70px
</style>
