<template>
  <div class="app-main" ref="scrollContainer">
    <div
      class="app-main-content"
      :class="{ 'app-main-content-hidden': !isHome }"
    >
      <UserInfoTips
        v-if="browserType !== 'landscape' && isHome"
        class="user-info-tips"
      />

      <div class="app-main-content-top">
        <PromoBanner />
        <div class="app-main-content-top-card horizontal">
          <NavList @click-nav="handleClickNav" />
        </div>
        <div class="app-main-content-top-mv">
          <MvCore />

          <MvMini v-if="isShowMini" />
        </div>
      </div>
      <NavList
        v-if="browserType === 'portrait'"
        class="vertical nav-list-vertical"
        @click-nav="handleClickNav"
      />
    </div>

    <router-view v-show="mvIsHide" v-slot="{ Component }">
      <keep-alive>
        <component
          :key="$route.meta.key"
          v-if="$route.meta.keepAlive"
          :is="Component"
        />
      </keep-alive>
    </router-view>
    <router-view v-show="mvIsHide" v-if="!$route.meta.keepAlive" />
    <BottomBar />
    <VantStyle></VantStyle>
    <OrderSongControlPopup></OrderSongControlPopup>
    <AIfaceOrderControlPopup></AIfaceOrderControlPopup>
    <FreeSinging></FreeSinging>
    <ClimaxModal></ClimaxModal>
    <PhoneSong
      v-show="
        mvIsHide && $route.name !== 'album' && $route.name !== 'vipaudition'
      "
    />
  </div>
</template>

<script>
import MvMini from '@/components/mv/mini.vue'
import PromoBanner from '@/components/nav-list/promo-banner.vue'
import UserInfoTips from '@/components/user-info-tips/index.vue'
import NavList from '@/pages/index/components/nav/index.vue'

import AIfaceOrderControlPopup from '@/components/ai-face-order/index.vue'
import MvCore from '@/components/mv-core/index.vue'
import OrderSongControlPopup from '@/components/order-song-control-popup/index.vue'
import useActivity from '@/composables/useActivity'
import useM3u8 from '@/composables/useM3u8'
import useNats from '@/composables/useNats'
import { sendLog } from '@/directives/v-log/log'
import { getRecommendSongList } from '@/service/recommendSong'
import { setUid } from '@/utils/bl'
import {
  getAlreadyList,
  getFreeSongNoTimeShow,
  getLoginSendVipActivityTag,
  getOrderedList,
  getSearchCache,
  getSearchSong,
  getVipActivityShowTag,
  setFreeSongNoTimeShow,
  setLoginSendVipActivityTag,
} from '@/utils/historyCache'
import Toast from '@/utils/toast'
import { format } from 'date-fns'
import { Dialog } from 'vant'
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
  watch,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import MvPageComponent from '@/components/mv/index.vue';
import FloatingAI from '@/components/ai/floating.vue'
import BottomBar from '@/components/bottom-bar/index.vue'
import ClimaxModal from '@/components/climax-modal/index.vue'
import FreeSinging from '@/components/free-singing/index.vue'
import SearchBarTips from '@/components/search-bar/tips.vue'
import useForceLogin from '@/composables/useForceLogin'
import useGlobalEvent from '@/composables/useGlobalEvent'
import useQuality from '@/composables/useQuality'
import useVip from '@/composables/useVip'
import formatStr, { vipLogFrom } from '@/constants/index'
import { initNatsClient } from '@/packages/nats'
import eventBus from '@/utils/event-bus'
import get from 'lodash/get'
import store2 from 'store2'
// import Index from "@/pages/index";
import PhoneSong from '@/components/phone-song/index.vue'
import { useShareBrowserSize } from '@/composables/sharedComposable'
import useLoginValidComposables from '@/composables/useLoginValid'
import useOrder from '@/composables/useOrder'
import useSongItem from '@/composables/useSongItem'
import logParams from '@/log'
import debounce from 'lodash/debounce'

export default {
  name: 'App',
  components: {
    MvCore,
    OrderSongControlPopup,
    AIfaceOrderControlPopup,
    // MvPageComponent,
    SearchBarTips,
    BottomBar,
    FloatingAI,
    FreeSinging,
    ClimaxModal,
    // Index,
    PhoneSong,
    MvMini,
    PromoBanner,
    NavList,
    UserInfoTips,
  },
  setup() {
    const scrollContainer = ref(null)
    let appLoading = ref(false)
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { addCurrSongM3u8 } = useM3u8()
    const { browserType } = useShareBrowserSize()
    const {
      showActivityModal,
      showActivityVip,
      // showVipExpire,
      // showActivityMic,
      showActivityRecommendSong,
      // showActivitySignIn,
      showActivityFreeSongNoTime,
      showActivityAiface,
    } = useActivity()
    const { showForceLogin } = useForceLogin()
    const { handleReceiveMessageFromNats } = useNats()
    const { installGlobalClickHandler, uninstallGlobalClickHandler } =
      useGlobalEvent()
    const { showVipQrcode } = useVip()
    const { orderSong } = useSongItem()
    const { checkUserLoginStatus, showLoginQrcode } = useLoginValidComposables()

    const setting = computed(() => store.state.setting)
    const isLogin = computed(() => !!userInfo.value.unionid)
    const userInfo = computed(() => store.state.userInfo)
    const vipInfo = computed(() => store.state.vipInfo)
    const macAddress = computed(() => store.state.macAddress)
    const natsConfig = computed(() => store.state.base.nats)
    const isForceLogin = computed(() => store.state.isForceLogin)
    const unionid = computed(() => store.state.userInfo.unionid)
    const isShowMini = computed(() => store.state.miniMv.isShowMini)
    const userType = computed(() => store.state.userInfo.userType)
    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const freeSing = computed(() => store.state.climax.freeSing)

    let checkIsWatchLoginSendVip = ref(false)
    let FNTInstance = ref(null)
    const showFreeSongNoTimeModal = ref(null)

    const loginSendVip = computed(() => store.state.activityInfo.loginSendVip)

    const isShowSignIn = computed(() => store.state.signin.isShowSignIn)

    const orderedSongIdMap = computed(() => store.state.orderedSongIdMap)
    provide('orderedSongIdMap', orderedSongIdMap)
    const curPlayingSongid = computed(
      () => store.state.videoPlayerHistory.songItem.songid
    )
    provide('playingSongId', curPlayingSongid)

    const videoPlayer = computed(() => store.state.videoPlayerHistory)
    const mvIsHide = computed(() => store.state.mvIsHide)
    const orderedListNumber = computed(() => store.state.orderedList.length)

    const { useMvQuality } = useQuality()
    const resolution = computed(() => store.state.carplayInfo.resolution)
    const isVersion2 = computed(
      () =>
        route.name === 'home' ||
        route.name === 'search' ||
        route.name === 'singer' ||
        route.name === 'mine' ||
        route.name === 'profile' ||
        route.name === 'setting' ||
        route.name === 'agreementUser' ||
        route.name === 'agreementPrivacy' ||
        route.name === 'vipExchange' ||
        route.name === 'singing' ||
        route.name === 'songList'
    )

    const isHome = computed(() => route.name === 'home')

    const freeVipNumber = computed(() => store.state.freeVipNumber)

    let appStartTime = Date.now()

    const abTestVersionTag = computed(() => store.state.abTest.abTestVersionTag)

    const initApp = () => {
      // 等待 ab test 版本标签设置完成
      store.dispatch('abTest/setABTestVersionTag')

      store.dispatch('getSetting')
      store.dispatch('getMacAddress')
      store.dispatch('getSystemInfo')
      store.dispatch('signin/setSignInActivityStatus')
      store.dispatch('act/setActList')

      store.dispatch('startCheckLoginKeepStatus')
      store.dispatch('startCheckAIface')
      store.dispatch('base/getBaseInfo')
      store.dispatch('base/getConfig')
      store.dispatch('base/reportSession')
      store.dispatch('abTest/setABTestVersion')
      // 初始化特斯拉top50常唱数据
      store.dispatch('oftenSing/initTop50List')
      store.dispatch('climax/fetchClimaxList')
      store.dispatch('miniMv/fetchDefaultList')
      // 启动上报
      sendLogWithABTest({
        event_type: 'custom',
        event_name: 241,
        event_data: {
          _car_language: navigator.language,
          _car_time: Date.now(),
          memory_max: get(performance, 'memory.jsHeapSizeLimit', 0),
          memory_used: get(performance, 'memory.usedJSHeapSize', 0),
        },
      })
      // app打开上报
      sendLogWithABTest({
        event_type: 'custom',
        event_name: 221,
        event_data: {
          start_time: format(appStartTime, formatStr),
        },
      })
      appLoading.value = true
      store.commit('UPDATE_APP_START_TIME', appStartTime)

      // 查看是否需要弹窗
      store.dispatch('openscreen/setOpenscreenInfo')

      // console.log('app init sss', !!store2('freeSing'))
      store.dispatch('climax/updateFreeSing', !!store2('freeSing'))
      store.dispatch('recommend/initTop100List')

      store.dispatch('miniMv/setMuted', !store.state.isFirstSong)
    }

    // 创建一个包装函数来处理依赖 abTest 的日志上报
    const sendLogWithABTest = (logData) => {
      if (!abTestVersionTag.value) {
        // 如果 abTest 标签还未加载完成，等待加载
        const unwatch = watch(abTestVersionTag, (newVal) => {
          if (newVal) {
            sendLog(logData)
            unwatch() // 清理 watch
          }
        })
      } else {
        // 如果已经加载完成，直接发送
        sendLog(logData)
      }
    }

    const onUnload = () => {
      //方案一：只在这里存储一次 貌似车机不太行 换即时存储
      // setOrderedList()
      // setAlreadyList()

      if (appStartTime) {
        const unloadTime = Date.now()
        const stayTime = Math.round((unloadTime - appStartTime) / 1000)
        sendLogWithABTest({
          event_type: 'custom',
          event_name: 222,
          event_data: {
            start_time: format(appStartTime, formatStr),
            end_time: format(unloadTime, formatStr),
            stay_time: stayTime,
          },
        })
      }
    }

    // 监听网络状态 先这样写 后面再看有没有更好的方式
    const onNetChange = () => {
      // console.log(e)
      Toast('当前网络状态差，请检查网络状态')
    }

    watch(appLoading, (val) => {
      if (val) {
        const appLoadingDom = document.getElementById('app-load')
        if (appLoadingDom) appLoadingDom.remove()
      }
    })

    watch(
      unionid,
      (val) => {
        if (val && !isVip.value) {
          store.dispatch('fetchFreeVip')
        }
      },
      {
        immediate: true,
      }
    )

    watch(isLogin, (val) => {
      if (!val) {
        if (setting.value.quality === '1080') {
          store.dispatch('saveSetting', {
            ...setting.value,
            quality: '720',
          })
        }
        store.dispatch('collect/resetUserCollectList')
        // 重置0元购活动状态
        store.dispatch('zerobuy/resetZerobuyStatus')
        if (isShowSignIn.value) store.dispatch('signin/resetSignInData')
        Dialog.confirm({
          className: 'global-force-login',
          confirmButtonText: '我知道了',
          showCancelButton: false,
          message: '监测到您的账号在当前车机已下线',
          beforeClose: () => {
            showForceLogin()
            return true
          },
        }).then(() => {
          router.push({
            name: 'home',
          })
        })
        setUid()
        // 退出登录时重置常唱数据
        store.dispatch('oftenSing/resetOftenSingList')

        // 获取3天限时vip免费活动字段 - 下线活动
        // store.dispatch("act/resetVipFreeActivity");
      } else {
        sendLog({
          event_type: '10000~50000',
          event_name: 30238,
          event_data: {
            str1: '通用',
            str2: '登录',
            str3: '扫码登录',
          },
        })
        store.dispatch('singTime/initSingTaskTime', userInfo.value.unionid)
        // 0元购活动任务及签到状态
        store.dispatch('zerobuy/updateZerobuyStatus', userInfo.value.unionid)
        store.dispatch('zerobuy/updateSigninStatus', userInfo.value.unionid)
        // 收藏功能下线
        // store.dispatch('collect/getUserCollectList', userInfo.value.unionid)
        if (isShowSignIn.value)
          store.dispatch('signin/setSignInData', userInfo.value.unionid)

        // 过期会员提醒 - 检测到登录用户会员过期时弹运营弹框 - 每天只弹一次 - offline
        // if (vipInfo.value.expire && getVipExpireTag()) {
        //   setVipExpireTag()
        //   showVipExpire({
        //     closeEvent: () => {
        //       store.dispatch('getCarplayInfo')
        //     },
        //     onCancel: () => {
        //       store.dispatch('getCarplayInfo')
        //     },
        //   })
        //   sendLog({
        //     event_type: 'show',
        //     event_name: 1752,
        //   })
        // }

        // 登录时更新常唱数据
        store.dispatch('oftenSing/initOftenSingList', userInfo.value.unionid)

        // 登录时更新云端画质到本地(非vip云端画质还为1080时，降级为720)
        let qualityNew =
          !isVip.value && resolution.value === '1080' ? '720' : resolution.value
        if (!qualityNew) qualityNew = '480' // 云端检测是否是新用户未设置过画质，新用户设置480
        useMvQuality(qualityNew)

        // 获取3天限时vip免费活动字段 - 下线活动
        // store.dispatch("act/getVipFreeActivity", userInfo.value.unionid);
      }
      // 检测到已登录时正常开启活动领取状态检测
      setTimeout(() => {
        checkIsWatchLoginSendVip.value = val
      }, 500)
    })

    // 已登录用户初始uid赋值
    watch(
      userInfo,
      (val) => {
        if (val.unionid) {
          setUid(val.unionid)
        }
      },
      {
        deep: true,
        immediate: true,
      }
    )

    // nats配置
    watch(
      natsConfig,
      (val) => {
        if (val) {
          const { eip } = val
          if (eip && macAddress.value) {
            initNatsClient({ ...val, mac: macAddress.value }, (msg) => {
              return handleReceiveMessageFromNats(msg)
            })
          }
        }
      },
      {
        deep: true,
        immediate: true,
      }
    )

    const openRecommendSongModal = async () => {
      console.log('openRecommendSongModal')
      // 已点无歌时显示
      if (orderedListNumber.value) return

      // 无开屏歌单时不显示
      let songList = await getRecommendSongList()
      if (!songList.length) return

      // 每日一次 可配置7天不显示
      const isFirst = get(store2('recommendSongRule'), 'showTime', '')
      const currDate = format(Date.now(), 'yyyy-MM-dd')
      if (isFirst === currDate) return
      const dateNow = new Date()
      const noShowRule = new Date(
        get(store2('recommendSongRule'), 'noShowTime', '2023-06-28')
      )
      if (dateNow < noShowRule) return

      sendLog({
        event_type: 'show',
        event_name: 3001,
        event_data: {
          str1: '1',
        },
      })
      // 开屏推荐歌曲（处于开屏显示优先级低，已点无歌时显示）
      showActivityRecommendSong({ songList })
    }

    // 设置活动标记 超过三次不显示领取弹框
    const setActivityTag = () => {
      // 初始未登录并默认打开了活动弹框时 关闭弹框后取消检测失败的场景 避免错误场景提示
      checkIsWatchLoginSendVip.value = false
      const currActTag = getLoginSendVipActivityTag()
      if (currActTag < 3) setLoginSendVipActivityTag(currActTag + 1)
    }

    // 检测车机是否已参与领取会员活动
    watch(loginSendVip, (val, old) => {
      // 会员领取关闭应用前只主动弹一次
      if (val === 1 && old === undefined) {
        if (getLoginSendVipActivityTag() > 2) {
          return
        }
        checkIsWatchLoginSendVip.value = true // 打开弹框 检测活动领取状态
        showActivityModal({
          closeEvent: setActivityTag,
        })
      }

      // 检测到账号或者车机已领取过
      if (val === 4 && old === 1) {
        Toast({
          message: `会员领取成功！有效期至${
            vipInfo.value?.end_time.split(' ')[0]
          }`,
          duration: 5000,
        })
      }

      if (!checkIsWatchLoginSendVip.value) return

      // 检测到账号或者车机已领取过
      if ([2, 3].includes(val) && old === 1) {
        Toast({
          message: '对不起，您的账号已经领取过了，不可以再领取了！',
          duration: 5000,
        })
      }
    })

    watch(mvIsHide, (v) => {
      // 进入过mv页面后默认初始化自动播放
      if (!v) {
        if (!store.state.videoInitAutoPlay) {
          store.commit('UPDATE_MV_INIT_AUTOPLAY', 1)
        }
        if (curPlayingSongid.value && !videoPlayer.value.songItem.hls) {
          addCurrSongM3u8(videoPlayer.value.songItem)
        }
      }
    })

    // 监测songid变化 请求歌曲资源 - 转到全局去处理 预防黑屏情况
    watch(curPlayingSongid, (val) => {
      if (val) {
        addCurrSongM3u8(videoPlayer.value.songItem)
      }
      // console.log('showFreeSongNoTimeModal', showFreeSongNoTimeModal.value);

      if (showFreeSongNoTimeModal.value) {
        const vipNoTimeShow = getFreeSongNoTimeShow()
        // console.log('showFreeSongNoTimeModal:videoPlayer.value.songItem.is_vip', videoPlayer.value.songItem.is_vip);
        // console.log('showFreeSongNoTimeModal:vipNoTimeShow', vipNoTimeShow);
        // console.log('showFreeSongNoTimeModal:isVip.value', isVip.value);
        // videoPlayer.value.songItem.is_vip &&
        if (!vipNoTimeShow && !isVip.value) {
          // Side Effects
          showFreeSongNoTimeModal.value = false
          FNTInstance.value = showActivityFreeSongNoTime()
          setFreeSongNoTimeShow(true)
        }
      }
    })

    watch(freeVipNumber, (val, old) => {
      console.log('freeVipNumber', val, old)
      if (val === 0 && old === 1) {
        const vipNoTimeShow = getFreeSongNoTimeShow()
        if (!vipNoTimeShow && !isVip.value) {
          // Side Effects
          console.log('showFreeSongNoTimeModal~')
          showFreeSongNoTimeModal.value = true
          // FNTInstance.value = showActivityFreeSongNoTime()
          // setFreeSongNoTimeShow(true)
        }
      }
    })

    onBeforeMount(initApp)

    const determineUserStatus = () => {
      if (vipInfo.value.end_time) {
        return 'vip' // VIP用户
      } else if (userInfo.value.unionid) {
        return 'loggedInButNotVip' // 非VIP登录用户
      }
      return 'notLoggedIn' // 未登录用户
    }

    // adjust cloud control open screen modal
    const openscreen1 = computed(() => {
      return store.state.openscreen.openscreen1
    })

    const openscreen2 = computed(() => {
      return store.state.openscreen.openscreen2
    })

    const openScreenModal = () => {
      const userStatus = determineUserStatus()
      const times = getVipActivityShowTag(userStatus)
      if (times) {
        showActivityVip({
          times: times === 1 ? 1 : 2,
          backgroundImageURL:
            times === 1 ? openscreen1.value : openscreen2.value,
        })
        sendLog({
          event_type: '10000~50000',
          event_name: times === 2 ? 30181 : 30180,
          event_data: {
            str1: `开屏弹窗${times}`,
            str2: '弹窗',
            str3: '二维码展示',
            str4: 'show',
          },
        })
        sendLog({
          event_type: 'show',
          event_name: 1723,
          event_data: {
            start_time: format(appStartTime, formatStr),
            end_time: format(appStartTime, formatStr),
            stay_time: 0,
            key_words: '销售VIP',
            type: 3,
          },
        })
      }
    }

    watch(openscreen1, (val) => {
      if (val) {
        openScreenModal()
      }
    })

    // 强制登录
    const forceLoginLogic = async () => {
      await store.dispatch('getCarplayInfo')

      setTimeout(() => {
        showForceLogin()
      }, 600)
    }

    const handleClickNav = (nav) => {
      if (nav.isCheckLogin && !checkUserLoginStatus()) {
        return
      }
      if (!nav.isSupport) {
        Toast('功能尚未完成迁移')
        return
      }
      if (nav.type === 'page') {
        if (nav.pathName === 'mine') {
          store.dispatch('getCarplayInfo')
        }
        if (nav.pathName === 'climax') {
          sendLog({
            event_type: '10000~50000',
            event_name: 30236,
            event_data: {
              str1: '首页',
              str2: '快唱高潮',
              str3: '点击',
              str4: 'click',
              str5: isLogin.value ? '已登录' : '未登录',
              str7: userType.value,
            },
          })
          if (!isLogin.value) {
            showLoginQrcode()
            return
          }
          if (isLogin.value && !isVip.value) {
            if (freeSing.value) {
              showVipQrcode({
                log: `首页-底部运营位`,
                isLogin: isLogin.value,
                fr: 1759,
              })
              return
            }
            eventBus.emit('free-singing-control-popup', nav.pathName)
            return
          }
          sendLog({
            event_type: '10000~50000',
            event_name: 30144,
            event_data: {
              str1: '首页',
              str2: '快唱高潮',
              str3: '快唱高潮点击',
              str4: 'click',
            },
          })
        }
        if (nav.pathName === 'songList') {
          router.push({
            name: 'songList',
            query: {
              name: '"特"别定制，会员专享歌单',
              image:
                'https://qncweb.ktvsky.com/20250313/vadd/55de41ab40c925a65b3699752b812bed.png',
              // 'https://qncweb.ktvsky.com/20241224/vadd/21217d957b4cc5a65530dc0e8c362217.png',
              // 'https://qncweb.ktvsky.com/common-web/operation_cms/1691133786343.png',
            },
          })
          sendLog(logParams.get(30312))
          return
        }
        // console.log('nav.pathName', nav.pathName)
        if (nav.pathName === 'singer') {
          sendLog({
            event_type: '10000~50000',
            event_name: 30005,
            event_data: {
              str1: '首页',
              str2: '歌手',
              str3: '歌手',
              str4: 'click',
            },
          })
        }
        router.push({
          name: nav.pathName,
        })
        return
      }
      if (nav.type === 'emit') {
        eventBus.emit(nav.emit)
        return
      }
      if (nav.type === 'custom') {
        if (nav.pathName === 'matching') {
          // already offline
          // showSocialMindModal()
          sendLog(logParams.get(30312))
        } else if (nav.pathName === 'aiface') {
          console.log('aimv')
          sendLog({
            event_type: '10000~50000',
            event_name: 20290,
            event_data: {
              str1: '首页',
              str2: '换脸MV',
              str3: '点击',
              str4: 'click',
            },
          })
          showActivityAiface({})
        }
      }
    }

    watch(orderedListNumber, (newNumber, oldNumber) => {
      if (newNumber > oldNumber) {
        store.dispatch('orderTime/updateNoVipOrderTime', {
          isVip: isVip.value,
          isLogin: isLogin.value,
          showVipQrcode,
          showVipQrcodeParam: {
            log: '免费歌曲主动弹',
            isLogin: isLogin.value,
            fr: vipLogFrom.get('免费歌曲主动弹-zd'),
          },
        })
      }
    })

    const vipAuditionSong = computed(() => store.state.vipAuditionSong)
    // const auditionCurrentTime = computed(() => store.state.auditionCurrentTime)

    const { orderSong: immediatePlay } = useOrder()

    // one minutes try play
    watch(isVip, (val) => {
      if (val && Object.keys(vipAuditionSong.value).length) {
        const song = {
          ...vipAuditionSong.value,
        }
        if (route.name === 'vipaudition') {
          store.commit('SET_VIP_AUDITION_SONG', {})
          router.back()

          const orderOptions = {
            // from: logFrom.value,
            isNotSingImmediately: false,
          }
          // eventBus.emit(
          //   'handle-video-start-position',
          //   auditionCurrentTime.value
          // )
          orderSong(song, orderOptions)
          setTimeout(() => {
            immediatePlay(song, orderedListNumber.value - 1)
          }, 500)

          // delay 3s reset auditionCurrentTime
          setTimeout(() => {
            store.commit('SET_AUDITION_CURRENT_TIME', 0)
          }, 3000)
        }
      } else {
        // 如果当前播放歌曲为vip
        if (videoPlayer.value.songItem.is_vip) {
          eventBus.emit('handle-video-pause')
        }
      }
    })

    onMounted(() => {
      // window.addEventListener('resize', () => {
      //   Toast(`当前宽度: ${window.innerWidth}px, 高度: ${window.innerHeight}px`);
      // });
      //初始化挂载本地历史已点已唱
      const [orderedList, alreadyList, searchCache, searchSong] = [
        getOrderedList(),
        getAlreadyList(),
        getSearchCache(),
        getSearchSong(),
      ]
      // 需求 - 每次进入应用暂时不同步已点数据
      // if (orderedList.length) {
      //   store.commit('SAVE_ORDERED_LIST', orderedList);
      //   // 此处只挂载歌曲id 歌曲不在此处挂载 车机上的浏览器和实际浏览器在- mv自动播放表现会不一致
      //   store.commit('SAVE_ORDERED_SONGIDMAP', orderedList[0]['songid']);
      // }
      if (alreadyList.length) {
        store.commit('SAVE_ALREADY_LIST', alreadyList)
      }
      if (searchCache.length) {
        store.dispatch('search/updateSearchCache', searchCache)
      }
      if (searchSong.length) {
        store.dispatch('search/initSearchSong', searchSong)
      }

      store.commit('INIT_AI_FACE')

      forceLoginLogic()

      // 使用 requestIdleCallback 或 setTimeout 延迟加载 mini mv
      if (window.requestIdleCallback) {
        requestIdleCallback(() => {
          store.dispatch('miniMv/setIsShowMini', true)
        })
      } else {
        setTimeout(() => {
          store.dispatch('miniMv/setIsShowMini', true)
        }, 0)
      }
    })

    const handleShowVipQrcode = (params) => {
      showVipQrcode(params)
    }

    const onXifenOrderSong = (payload) => {
      orderSong(payload.songInfo, {
        ...payload.option,
        immediate: orderedListNumber.value === 0,
        enabledMvShow: false,
      })
    }

    let ticking = false
    const mvCoreHeight = 420 // 假设 mv-core 的高度为 50px
    const threshold = mvCoreHeight + 10 // 滚动阈值
    const isShowBottomBar = computed(() => store.state.isShowBottomBar)
    const isPortrait = ref(false)

    // 检测屏幕方向
    const updateOrientation = () => {
      isPortrait.value = window.matchMedia('(orientation: portrait)').matches
    }

    const handleScroll = debounce(() => {
      // console.log('handleScroll')
      if (!isHome.value) return
      if (ticking) return
      ticking = true

      const scrollY = scrollContainer.value.scrollTop
      console.log('scrollY', scrollY)
      // 竖屏判断
      if (isPortrait.value) {
        if (scrollY >= 700) {
          eventBus.emit('isScroll', true)
        } else {
          eventBus.emit('isScroll', false)
        }
      } else {
        // 横屏判断
        if (scrollY >= 1200) {
          eventBus.emit('isScroll', true)
        } else {
          eventBus.emit('isScroll', false)
        }
      }
      if (scrollY > threshold) {
        if (!isShowBottomBar.value) store.commit('UPDATE_ISSHOWBOTTOMBAR', true)
      } else {
        if (isShowBottomBar.value) store.commit('UPDATE_ISSHOWBOTTOMBAR', false)
      }

      ticking = false
    }, 50)

    onMounted(() => {
      window.addEventListener('beforeunload', onUnload)
      // plusReady(netChange)
      window.addEventListener('offline', onNetChange)
      installGlobalClickHandler()

      eventBus.on('show-vip-qrcode', handleShowVipQrcode)
      eventBus.on('xifen-order-song', onXifenOrderSong)

      updateOrientation() // 初始化方向
      window.addEventListener('resize', updateOrientation) // 监听窗口大小变化
      if (scrollContainer.value) {
        // 监听滚动事件
        // console.log('绑定滚动事件')
        scrollContainer.value.addEventListener('scroll', handleScroll, {
          passive: true,
        })
      }
    })
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', onUnload)
      window.removeEventListener('offline', onNetChange)
      uninstallGlobalClickHandler()
      window.removeEventListener('resize', updateOrientation) // 移除监听器
      eventBus.off('show-vip-qrcode', handleShowVipQrcode)
      eventBus.off('xifen-order-song', onXifenOrderSong)
      if (scrollContainer.value) {
        scrollContainer.value.removeEventListener('scroll', handleScroll, {
          passive: true,
        })
      }
    })

    return {
      scrollContainer,
      browserType,
      mvIsHide,
      isVersion2,
      route,
      isHome,
      isShowMini,
      handleClickNav,
    }
  },
}
</script>

<style lang="stylus">
.app-main
  position relative
  overflow scroll
  background #e8eaee
  height 100vh

  // &.version2
  //   background: radial-gradient(121% 203.64% at 95% 9%, #ffb3ff -3%, #697cff 31%, #a1efff 49%, #007c88 79%, #00172e 90%)
  //   .bottom-bar
  //     background: #FFFFFF1A
  //     border: 1px solid #FFFFFF26
  //     border-radius 10px
  //     backdrop-filter: blur(150px);

  .app-main-content-hidden
    height 0
    overflow hidden
    margin-top 0px !important
  .app-main-content
    display flex
    flex-direction column
    margin-top 150px
    @media screen and (max-width 1200px) and (min-height 1200px)
      & > *
        padding-right 64px

    .user-info-tips
      margin-bottom 28px
      margin-left 68px
      margin-top 0

    .nav-list-vertical
      padding-left 68px

    &-top
      width 100%
      height 420px
      margin-top 12px
      margin-bottom 48px
      padding-left 80px
      display flex
      align-items center
      @media screen and (max-width 1200px) and (min-height 1200px)
        margin-top 0px
        margin-left 0
        padding-left 68px
        height 378px
        margin-bottom 28px

        &-mv
          margin-left 28px

      &-card
        width 552px
        height 420px
        margin-right 24px
</style>
