<template>
  <CommonModal ref="modalRef" class="new-vip-openscreen-outer">
    <!-- 新的VIP运营弹窗 -->
    <div
      class="new-vip-modal"
      :style="{
        '--background-img': `url(${backgroundImageURL})`,
      }"
    >
      <div class="close-btn" @click="closeModal">
        <svg
          width="96"
          height="96"
          viewBox="0 0 96 96"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_129_1090)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M30.0955 28.2079L28.2099 30.0935L46.124 48.0075L28.2111 65.9204L30.0967 67.806L48.0096 49.8932L65.9223 67.8058L67.8079 65.9202L49.8952 48.0075L67.8091 30.0937L65.9235 28.2081L48.0096 46.1219L30.0955 28.2079Z"
              fill="white"
              fill-opacity="0.3"
            />
          </g>
          <path
            d="M95.0051 48C95.0051 73.9601 73.9602 95.0049 48.0001 95.0049C22.0399 95.0049 0.995066 73.9601 0.995066 48C0.995066 22.0399 22.0399 0.995066 48.0001 0.995066C73.9602 0.995066 95.0051 22.0399 95.0051 48Z"
            stroke="white"
            stroke-opacity="0.3"
            stroke-width="1.99013"
          />
          <defs>
            <clipPath id="clip0_129_1090">
              <rect
                width="53.3334"
                height="53.3333"
                fill="white"
                transform="translate(21.3438 21.3408)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>

      <!-- <div class="new-vip-modal-title">
        <img
          src="https://qncweb.ktvsky.com/20250225/vadd/cd35227d28d989052332ad891d78c249.png"
          alt=""
        />
      </div>

      <div class="new-vip-modal-price">
        <img
          src="https://qncweb.ktvsky.com/20250225/vadd/ff92a5c310cbf260fdb51954fb6aa50b.png"
          alt=""
        />
      </div>

      <div class="new-vip-modal-bottom">
        <img
          src="https://qncweb.ktvsky.com/20250225/vadd/606ec27b513cb219396e73f7667ad957.png"
          alt=""
        />
      </div> -->

      <div class="content-container">
        <div class="qr-code-wrapper">
          <img :src="qrCodeImage" alt="二维码" />
        </div>
        <!-- <span>扫码开通VIP</span> -->
      </div>
    </div>
  </CommonModal>
</template>

<script setup>
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
import IMAGE_URLS from '@/constants/images/index'
import { vipLogFrom } from '@/constants/index'
import { sendLog } from '@/directives/v-log/log'
import { getCarplayInfo } from '@/service/carplay-info'
import store from '@/store'
import Toast from '@/utils/toast'
import { format } from 'date-fns'
import { get } from 'lodash'
import { computed, defineProps, onBeforeMount, ref, toRefs, watch } from 'vue'

const props = defineProps({
  backgroundImageURL: {
    type: String,
    default: IMAGE_URLS.VIP_MODAL.SPRING_BACKGROUND,
  },
  closeEvent: {
    type: Function,
    default: () => {},
  },
  times: {
    type: Number,
    default: 1,
  },
})

const modalRef = ref(null)
const { times } = toRefs(props)

const appStartTime = computed(() => store.state.appStartTime)
const userType = computed(() => store.state.userInfo.userType)
const abTestVersionTag = computed(() => store.state.abTest.abTestVersionTag)

const { getQRCodeURL } = useQRCode()
let qrCodeImage = ref(IMAGE_URLS.VIP_MODAL.DEFAULT_QR_CODE)

// const backgroundImageURL = ref(IMAGE_URLS.VIP_MODAL.SPRING_BACKGROUND)

const fetchQRCode = async () => {
  const { data } = await getCarplayInfo()
  if (get(data, 'pay_qr', '')) {
    const qrData = await getQRCodeURL(
      `${data.pay_qr}&fr=520&log=${vipLogFrom.get(
        `开屏弹窗${times.value === 2 ? 2 : ''}`
      )}&ut=${userType.value}`
    )
    if (qrData) {
      qrCodeImage.value = qrData
    }
    return
  }
  Toast('未获取到登录二维码')
}

watch(abTestVersionTag, (val) => {
  if (val) {
    fetchQRCode()
  }
})

const closeModal = () => {
  const now = Date.now()
  props.closeEvent.call()
  sendLog({
    event_type: 'show',
    event_name: 1723,
    event_data: {
      start_time: format(appStartTime.value, 'yyyy-MM-dd HH:mm:ss'),
      end_time: format(now, 'yyyy-MM-dd HH:mm:ss'),
      stay_time: Math.round((now - appStartTime.value) / 1000),
      key_words: 'VIP销售',
      type: 3,
    },
  })
  modalRef.value.hide()
}

onBeforeMount(fetchQRCode)
</script>

<style lang="stylus" scoped>
.new-vip-openscreen-outer
  align-items normal
  @media screen and (max-width 1200px) and (min-height 1200px)
    align-items center
.new-vip-modal
  position: relative
  width: 1105px
  height: 923px
  background: var(--background-img) no-repeat
  background-size 100% 100%
  background-position center
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  color: rgba(255, 51, 100, 1)

  &-title
    position absolute
    top: 190px
    left: 34px
    width 836px
    height 151px
    img
      width 100%
      height 100%

  &-price
    position absolute
    top: 234px
    left: 89px
    width 609px
    height 268px
    img
      width 100%
      height 100%

  &-bottom
    position absolute
    bottom: 60px
    left: 89px
    width 922px
    height 79px
    img
      width 100%
      height 100%

  .close-btn
    position: absolute
    top: 80px
    right: -225px
    width: 96px
    height: 96px
    cursor: pointer
    // For close icon
    img
      width: 100%;
      height: 100%;
    @media screen and (max-width 1200px) and (min-height 1200px)
      right: -125px

  .content-container
    position: absolute
    bottom 274px
    right 142px
    display: flex
    justify-content: normal
    align-items: normal
    flex-direction: column
    width 210px
    height 210px
    border-radius 12px
    // background rgba(169, 215, 149, 1)

    .qr-code-wrapper
      position: relative
      display: flex
      justify-content: center
      align-items: center
      width: 210px
      height: 210px
      background-color: #fff
      border-radius: 10px
      img
        width: 200px
        height: 200px
        // border-radius: 8px

    span
      font-size: 28px
      font-weight: 500
      color: rgba(94, 94, 94, 1)
      margin-top: 2px

@media screen and (max-width: 1200px)
  .new-vip-modal
    zoom: 0.85
</style>
