<template>
  <div class="times-info" @click="handleClickTimes">
    <template v-if="!isLogin">
      <!-- <span class="light">登录</span>即可享千元特权，好礼送不停！ -->
      <span class="light">登录领取</span>VIP权益，好礼送不停！
      <!-- src="https://qncweb.ktvsky.com/20240925/vadd/08e914c55df65ba848439f6e12293197.png" -->
      <img
        src="https://qncweb.ktvsky.com/20241224/vadd/a65937cd750c12ead085901befc5ebde.png"
        alt=""
      />
    </template>
    <template v-else-if="isVip"
      >尊敬的VIP用户，拉满状态开启您狂欢时刻！</template
    >
    <template v-else-if="isExpire"
      >权益重磅升级，邀您回归！<span class="light"
        >续费低至¥0.2/天</span
      ></template
    >
    <template v-else>
      <p>每日更新，紧跟热点，点我，海量歌曲免费唱！</p>
      <p v-if="showFreeOrderInfo" class="free-order">
        VIP歌曲免费点唱次数:
        <span class="free-order-zero">{{ remainingFreeOrders }}/3</span>
      </p>
      <p v-if="isShowClimax" class="climax-free">
        快唱高潮限免时长
        <CountDown
          class="climax-free-countdown"
          ref="userTime"
          :time="preferentialTime"
          @finish="handleTimeFinish"
          :auto-start="false"
        >
          <template #default="timeData">
            <span class="block">00</span>
            <span class="colon">:</span>
            <span class="block">{{ formattedMinutes(timeData.minutes) }}</span>
            <span class="colon">:</span>
            <span class="block">{{ formattedSeconds(timeData.seconds) }}</span>
          </template>
        </CountDown>
      </p>
    </template>
  </div>
</template>

<script>
import useLoginValid from '@/composables/useLoginValid'
import useVip from '@/composables/useVip'
import { sendLog } from '@/directives/v-log/log'
import { CountDown } from 'vant'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'UserInfoTips',
  components: { CountDown },

  setup() {
    const store = useStore()
    const { showVipQrcode } = useVip()
    const { showLoginQrcode, isLogin } = useLoginValid()

    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const vipInfo = computed(() => store.state.vipInfo)
    const freeVipNumber = computed(() => Number(store.state.freeVipNumber1))
    const isExpire = computed(() => vipInfo.value.expire)
    const isClimaxFree = computed(() => !store.state.climax.freeSing)
    // const isShowClimax = computed(() => !isVip.value && isClimaxFree.value)
    const isShowClimax = ref(false)

    const showFreeOrderInfo = computed(
      () => !isExpire.value && !isShowClimax.value && freeVipNumber.value > 3
      // freeVipNumber.value
    )
    const remainingFreeOrders = computed(() => 3 - freeVipNumber.value)

    const preferentialTime = ref(30 * 60 * 1000)
    const userTime = ref(null)

    const handleTimeFinish = () => {
      // store.dispatch('climax/updateFreeSing', true)
    }

    const handleClickTimes = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 30256,
        event_data: {
          str1: '首页',
          str2: '右上角喇叭通知',
          str3: '点击',
          str4: 'click',
        },
      })
      if (!isVip.value) {
        if (isLogin.value) {
          showVipQrcode({
            // 这里可能需要定义 showVipQrcode 函数
            isLogin: isLogin.value,
            fr: 1868,
            log: '权益顶部运营位',
          })
        } else {
          showLoginQrcode({
            log: '权益顶部运营位',
          })
        }
      }
    }

    const formattedMinutes = (minutes) => {
      return minutes < 10 ? `0${minutes}` : minutes
    }

    const formattedSeconds = (seconds) => {
      return seconds < 10 ? `0${seconds}` : seconds
    }

    return {
      isLogin,
      isVip,
      isExpire,
      isShowClimax,
      showFreeOrderInfo,
      remainingFreeOrders,
      preferentialTime,
      userTime,
      formattedMinutes,
      formattedSeconds,
      handleTimeFinish,
      handleClickTimes,
    }
  },
}
</script>

<style lang="stylus" scoped>
$white-color = #FFFFFF
$white-opacity-20 = rgba(255, 255, 255, 0.2)
$white-opacity-15 = rgba(255, 255, 255, 0.15)
$white-opacity-30 = rgba(255, 255, 255, 0.3)
$white-opacity-80 = rgba(255, 255, 255, 0.8)
$border-radius-24 = 24px
$border-radius-16 = 16px
$font-size-24 = 24px
$font-size-30 = 30px

.times-info
    display flex
    align-items center
    // background-color rgba(255, 255, 255, 0.8)
    background rgba(0, 0, 0, 0.04)
    height 76px
    // background-image url('https://qncweb.ktvsky.com/20240710/other/b8108ad12e7dcc159db670aa63f8daa6.png')
    background-image url('https://qncweb.ktvsky.com/20241224/vadd/4998b9f7854e9623f5f1b9d1d6a44d06.png')
    background-size 48px auto
    background-repeat no-repeat
    background-position 20px center
    border-radius 16px
    // color rgba(29, 29, 31, 0.9)
    color rgba(29, 29, 31, 0.5)
    font-size 28px
    padding-left 78px
    padding-right 20px
    margin-left 32px
    img
      width 32px
      height 32px
    @media screen and (max-width 1200px) and (min-height 1200px)
      // width calc(100vw - 120px)
      width max-content
      height 64px
      margin-top -20px
      margin-left 0
      font-size 24px
      padding-left 70px
      padding-right 20px
      padding-top 18px
      padding-bottom 18px
      background-image url('https://qncweb.ktvsky.com/20241224/vadd/4998b9f7854e9623f5f1b9d1d6a44d06.png')
      background-size 40px auto
      background-position 20px center
      border-radius $border-radius-16
      img
        width 28px
        height 28px
    .light
      color rgba(221, 153, 73, 1)
      font-size 28px

    p
      color rgba(29, 29, 31, 0.9)

    .free-order
      position relative
      padding-right 20px
      margin-right 20px
      &:after
        content ''
        position absolute
        top 50%
        margin-top -8px
        right 0
        width 2px
        height 20px
        background rgba(255, 255, 255, 0.2)
    .climax-free
      display flex

      &-countdown
        color rgba(0, 0, 0, 0.2)
        margin-left 10px
        .block
          display inline-block
          background rgba(0, 0, 0, 0.2)
          width 28px
          height 28px
          line-height 28px
          text-align center
          font-size 18px
          border-radius 4px
          color $white-opacity-80
        .colon
          margin 0 4px
</style>
