<template>
  <div class="try-sing-qrcode-pkg">
    <div class="pkg-content" v-for="item in pkg" :key="item.id">
      <div
        class="pkg-item"
        :class="{ selected: selected === item.id }"
        @click="handleClick(item)"
      >
        <p class="pkg-name">{{ item.title }}</p>
        <p class="pkg-desc">
          ￥ <span>{{ item.dayFee }}</span
          >/天
        </p>
        <p class="pkg-price">
          <span>{{ item.price }}</span>
          <span v-if="item.price !== item.oldPrice">{{ item.oldPrice }}</span>
        </p>

        <div class="pkg-tip" v-if="item.desc">{{ item.desc }}</div>

        <!-- 限时：01:59:59 -->
        <div class="pkg-limit-time" v-if="item.limitTimer">
          限时：{{ formatTime(countdown) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue'

export default {
  name: 'TrySingQrcodePkg',

  props: {
    pkg: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Number,
      default: 0,
    },
  },

  emits: ['choose'],

  setup(props, { emit }) {
    const countdown = ref(7200) // 初始值设为2小时 (7200秒)
    let timer = null

    const formatTime = (seconds) => {
      const h = Math.floor(seconds / 3600)
      const m = Math.floor((seconds % 3600) / 60)
      const s = seconds % 60
      return `${h.toString().padStart(2, '0')}:${m
        .toString()
        .padStart(2, '0')}:${s.toString().padStart(2, '0')}`
    }

    const startCountdown = () => {
      timer = setInterval(() => {
        if (countdown.value > 0) {
          countdown.value--
        } else {
          clearInterval(timer)
        }
      }, 1000)
    }

    onMounted(() => {
      startCountdown()
    })

    onUnmounted(() => {
      if (timer) {
        clearInterval(timer)
      }
    })

    const handleClick = (val) => {
      console.log('choose pkg:', val)
      emit('choose', val)
    }

    return {
      handleClick,
      countdown,
      formatTime,
    }
  },
}
</script>

<style lang="stylus" scoped>
.try-sing-qrcode-pkg
  width 980px
  height 364px
  display flex
  flex-wrap wrap
  justify-content space-between
  .pkg-content
    display flex
    flex-wrap wrap
    justify-content space-between
    .selected
      background: linear-gradient(321.82deg, #FFE093 5.61%, #FFEBB5 34.88%, #FFECB9 96.2%) !important;
      .pkg-name
        background: linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        --webkit-text-fill-color: transparent;
        color unset !important
      .pkg-desc
        color rgba(136, 55, 0, 1) !important
      .pkg-price
        span
          &:nth-child(1)
            color rgba(172, 98, 0, 1) !important
          &:nth-child(2)
            color rgba(172, 98, 0, 0.5) !important
    .pkg-item
      width 280px
      height 364px
      border-radius 14px
      background #000 linear-gradient(156.56deg, rgba(255, 238, 222, 0.2) 4.5%, rgba(255, 255, 255, 0.04) 96.81%);
      box-sizing border-box
      position relative
      display flex
      flex-direction column
      align-items center
      justify-content center
      .pkg-name
        font-size 39px
        font-weight 300
        color rgba(250, 217, 179, 1)
        text-align center
        height 46px
        line-height 46px
      .pkg-desc
        font-size 34px
        font-weight 300
        color rgba(250, 217, 179, 1)
        text-align center
        height 85px
        line-height 85px
        margin 30px 0
        span
          font-size 58px
      .pkg-price
        width 100%
        height 40px
        display flex
        justify-content center
        align-items center
        // border-radius 20px
        // background rgba(250, 217, 179, 1)
        font-size 34px
        height 39px
        line-height 39px
        span
          font-weight 300
          font-size 34px
          color rgba(255, 255, 255, 1)
          &:nth-child(1)
            color rgba(255, 255, 255, 1)
            font-weight 300
            font-size 34px
          &:nth-child(2)
            color rgba(255, 255, 255, 0.5)
            font-weight 300
            font-size 34px
            margin-left 12px
      .pkg-tip
        width 154px
        height 56px
        background: linear-gradient(90deg, #FF3D3D 0%, #FF881A 100%);
        font-size 31px
        font-weight 500
        color rgba(255, 255, 255, 1)
        display flex
        align-items center
        justify-content center
        position absolute
        top -22px
        left 0
        border-radius 20px 20px 0 0
      .pkg-limit-time
        width 280px
        height 50px
        font-size 31px
        display flex
        align-items center
        justify-content center
        position absolute
        bottom 0px
        left 0
        border-radius 0 0 12px 12px
        color rgba(255, 255, 255, 1)
        background rgba(243, 63, 63, 1) // linear-gradient(90deg, #FF3D3D 0%, #FF881A 100%)
</style>
