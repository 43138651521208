import { createComponent } from '@/components/modal/utils/index.js'
import VipAbMModalVue from './ab_m.vue'
import VipAbZdModalVue from './ab_zd.vue'

import VipModalVue from './index.vue'

import VipLoggedInModalVue from './loggedIn.vue'

export default function useVipQrcode(globalProps = {}, globalSlots = {}) {
  function show(Component, props = globalProps, slots = globalSlots) {
    const forceProps = {
      programmatic: true,
      lockScroll: true,
      isFullPage: true,
      active: true,
    }

    const propsData = { ...globalProps, ...props, ...forceProps }
    const container = document.body

    const mergedSlots = { ...globalSlots, ...slots }
    const instance = createComponent(
      Component,
      propsData,
      container,
      mergedSlots
    )

    return {
      hide: instance.refs.root.hide,
    }
  }
  return {
    show(props = globalProps, slots = globalSlots) {
      if (props.isLogin) {
        if (!props.isATestVersionTag) {
          return this.showAbZd(props, slots)
        }
        return this.showLoggedin(props, slots)
      }
      if (!props.isATestVersionTag) {
        return this.showAbM(props, slots)
      }
      return show(VipModalVue, props, slots)
    },
    showLoggedin(props = globalProps, slots = globalSlots) {
      return show(VipLoggedInModalVue, props, slots)
    },
    showAbM(props = globalProps, slots = globalSlots) {
      return show(VipAbMModalVue, props, slots)
    },
    showAbZd(props = globalProps, slots = globalSlots) {
      return show(VipAbZdModalVue, props, slots)
    },
  }
}
