<template>
  <div class="recommend-chat-bg"></div>
  <div class="recommend-chat">
    <!-- <div class="header">
      <div class="timer" v-if="countdownTimer < 31">{{ countdownTimer }}s</div>
      <img
        src="https://qncweb.ktvsky.com/20240823/vadd/3a9a677908b398f3da7c2a798989b7b0.png"
        alt="Close"
        @click="closeChat"
      />
    </div> -->
    <div class="chat-list" ref="chatRefs">
      <div class="chat-list-hello">
        <img :src="singerHeader" alt="Avatar" />
        <div class="chat-list-hello-content">
          Hello，我是{{ singername }}，这首歌在你的演唱下有了新的味道！
          <div class="chat-list-hello-content-btn" @click.stop="playAgin">
            再唱一遍
          </div>
        </div>
      </div>
      <div
        v-for="(chat, index) in chatList"
        :key="chat.id"
        class="chat-list-item"
      >
        <img :src="singerHeader" alt="Avatar" />
        <div class="chat-list-item-content">
          <div class="chat-list-item-content-title">
            {{ chat.content }}
            <div
              v-if="index === chatList.length - 1"
              class="chat-list-item-content-title-btn"
              @click.stop="changeChatBatch"
            >
              <img
                src="https://qncweb.ktvsky.com/20241203/vadd/9b49b4d2dd3c654226df5a87d092f8fe.png"
                alt=""
              />换一批
            </div>
          </div>
          <!-- <song-list @songClick="handleSongClick" /> -->
          <div
            class="chat-list-item-content-song"
            v-if="chat.list && chat.list.length"
          >
            <template v-for="(song, i) in chat.list" :key="i">
              <div class="dis_flex align_center">
                <div class="num">{{ i + 1 }}</div>
                <SongItem
                  :isStickButton="false"
                  :useWIcon="true"
                  class="chat-list-item-content-song-item"
                  :songItem="song"
                  :log-from="{
                    fr1: 1884,
                    fr2: '欢唱页-智能推荐',
                  }"
                  @singer-click="handleClickSinger"
                  @order-click="handleOrderAction"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-bot-btn" @click="closeChat">
      <span>关闭</span>
    </div>
  </div>
</template>

<script setup>
import SongItem from '@/components/song-item'
import { defineEmits, defineProps, nextTick, onMounted, ref, watch } from 'vue'

import { searchSinger } from '@/service/search'

import useRecommend from '@/composables/useRecommend'

import {
  getCloseMvRecommendTag,
  setCloseMvRecommendTag,
} from '@/utils/historyCache'

import Toast from '@/utils/toast'

import { sendLog } from '@/directives/v-log/log'

const emit = defineEmits(['time-end', 'playAgin'])

const props = defineProps({
  singerid: {
    type: String,
    default: '',
  },
  singername: {
    type: String,
    default: '',
  },
})

const { showRecommendConfirm } = useRecommend()

let chatRefs = ref(null)
let songList = []
let singerHeader = ref(
  'https://qncweb.ktvsky.com/20231226/vadd/a3056a76c9626aa4d68318625ae87a35.png'
)

let countdownTimer = ref(15)
let timer = null

let hasOrderTag = false

let chatList = ref([
  // { id: 1, content: "Chat content 1" },
  // { id: 2, content: "Chat content 2" },
  // { id: 3, content: "Chat content 3" },
  // Add more chat content as needed
])

const startReduceTimer = () => {
  if (timer) clearInterval(timer)
  countdownTimer.value = 15
  timer = setInterval(() => {
    countdownTimer.value--
    if (countdownTimer.value <= 0) {
      closeChat()
    }
  }, 1000)
}

onMounted(() => {
  init()
  startReduceTimer()
  sendLog({
    event_type: '10000~50000',
    event_name: 30266,
    event_data: {
      str1: '欢唱页',
      str2: '智能推荐',
      str3: '展示',
      str4: 'show',
    },
  })
})

const nosongToast = () => {
  Toast({
    message: '暂无更多歌曲～',
    position: 'center',
    className: 'toast-zoom-max',
  })
}

const requestData = async () => {
  // console.log("mv requestData init", props.singerid);
  if (props.singerid) {
    const { data } = await searchSinger(props.singerid, 1, 100)
    // console.log("data", data);
    try {
      songList = data.song
      if (!songList || !songList.length) {
        nosongToast()
        return
      }
      // console.log("requestData songList data.song", data.song);
      // 截取songList前3个元素，并改变原数组
      let first = songList.splice(0, 3)
      // songList = songList.concat(first);
      // console.log("requestData songList", songList);

      chatList.value = [
        {
          id: 1,
          content: '我的这些歌曲都很好听，你一定可以赋予这些歌曲新的感觉！',
          list: first,
        },
      ]
      // console.log("chatList:", chatList.value);
      singerHeader.value = data.singerhead ? data.singerhead : ''
    } catch (error) {
      console.log('error', error)
    }
  }
}

// watch(() => props.singerid, () => {

// })

const init = () => {
  console.log('mv chat init')
  countdownTimer.value = 15
  songList = []
  chatList.value = []
  hasOrderTag = false
  requestData()
}

const closeChat = () => {
  // Logic to close the chat component
  if (timer) clearInterval(timer)
  countdownTimer.value = 15
  emit('time-end')

  sendLog({
    event_type: '10000~50000',
    event_name: 30268,
    event_data: {
      str1: '欢唱页',
      str2: '智能推荐-关闭',
      str3: '点击',
      str4: 'click',
    },
  })

  if (hasOrderTag) return

  // 无操作关闭
  sendLog({
    event_type: '10000~50000',
    event_name: 30281,
    event_data: {
      str1: '欢唱页',
      str2: '智能推荐-无行为关闭',
      str3: '点击',
      str4: 'click',
    },
  })

  let closeMvRecommendTag = getCloseMvRecommendTag()

  if (closeMvRecommendTag > 2) return
  if (closeMvRecommendTag === 2) {
    //3次无操作
    showRecommendConfirm()
    sendLog({
      event_type: '10000~50000',
      event_name: 30262,
      event_data: {
        str1: '欢唱页',
        str2: '智能推荐-设置弹窗',
        str3: '展示',
        str4: 'show',
      },
    })
  }
  setCloseMvRecommendTag(closeMvRecommendTag + 1)
}

const handleClickSinger = (song) => {
  // Logic to handle song click
}

const handleOrderAction = () => {
  // Logic to handle order action
  console.log('order song')
  hasOrderTag = true

  // close timer
  if (timer) clearInterval(timer)
  countdownTimer.value = 31

  autoRecommendChat()

  sendLog({
    event_type: '10000~50000',
    event_name: 30267,
    event_data: {
      str1: '欢唱页',
      str2: '智能推荐-点歌',
      str3: '点击',
      str4: 'click',
    },
  })
}

const changeChatBatch = () => {
  // Logic to change chat batch
  // console.log("changeChatBatch songList", songList);
  if (!songList || !songList.length) {
    nosongToast()
    return
  }

  let first = songList.splice(0, 3)
  // songList = songList.concat(first);
  chatList.value[chatList.value.length - 1].list = first
}

const autoRecommendChat = () => {
  // Logic to auto recommend chat
  if (!songList || !songList.length) {
    nosongToast()
    return
  }

  let first = songList.splice(0, 3)
  // songList = songList.concat(first);
  // console.log("autoRecommendChat songList", songList);
  chatList.value = [
    ...chatList.value,
    {
      id: chatList.value.length + 1,
      content: '您的眼光真不错，此歌曲已为您加入已点，我的这些歌也很不错哦',
      list: first,
    },
  ]
  nextTick(() => {
    chatRefs.value.scrollTop = chatRefs.value.scrollHeight
  })
}

const playAgin = () => {
  emit('playAgin')
}

watch(props.singerid, () => {
  init()
  startReduceTimer()
})
</script>

<style lang="stylus" scoped>
.recommend-chat
  position absolute
  top 40px
  left 50%
  transform translateX(-50%)
  z-index 999
  background rgba(43, 48, 59, 0.7)
  backdrop-filter blur(200px)
  width 1113px
  height 686px
  border-radius 32px
  padding 40px 0 0 40px
  color #fff
  .header
    position absolute
    top: 20px;
    right: 20px;
    display: flex;
    width 110px
    height 33px
    .timer
      /* Styles for countdown timer */
      font-size: 20px; /* Example font size */
      color: #ffffff; /* Example color */
      /* Add styles based on UI design for countdown timer */
    img
      width: 32px; /* Example width for close button */
      height: 32px; /* Example height for close button */
      cursor: pointer; /* Add pointer cursor for interaction */
      /* Add styles based on UI design for close button */
      margin-left 33px
  .chat-list
    /* Styles for chat list */
    width 100%
    height 505px
    padding 0
    padding-bottom 14px
    overflow-x hidden
    overflow-y scroll
    &-hello
      width 100%
      display flex
      align-items normal
      img
        width 72px
        height 72px
        margin-right 27px
        border-radius 50%
      &-content
        padding 16px 20px 16px 25px
        background url(https://qncweb.ktvsky.com/20241203/vadd/07fb56a15aad73c44966271e84930439.png) no-repeat
        background-size 100% 100%
        width 941px
        min-height 60px
        height auto
        display flex
        align-items center
        font-size 24px
        line-height 1
        justify-content space-between
        &-btn
          width 180px
          height 60px
          margin-left 0
          background linear-gradient(90deg, #FF5A6E 0%, #FF73B7 100%)
          border-radius 4px
          border 2px solid rgba(255, 255, 255, 0.1)
          color rgba(255, 255, 255, 1)
          font-size 26px
          display flex
          align-items center
          justify-content center
    &-item
      width 100%
      display flex
      align-items normal
      margin-top 20px
      img
        width 72px
        height 72px
        margin-right 27px
        border-radius 50%
      &-content
        padding-top 16px
        padding-left 25px
        background url(https://qncweb.ktvsky.com/20241203/vadd/cfb75730814e0ec0493bbc4f654fb7b1.png) no-repeat
        background-size 100% auto
        // background-color rgba(255, 255, 255, 0.1)
        width 941px
        min-height 393px
        height fit-content
        padding-bottom 16px
        display flex
        flex-direction column
        // align-items center
        // padding-left 106px
        &-title
          font-size 24px
          color rgba(255, 255, 255, 0.8)
          margin-bottom 0px
          padding-right 20px
          display flex
          align-items center
          justify-content space-between
          &-btn
            width 180px
            height 60px
            margin-left 0
            background rgba(255, 255, 255, 0.1)
            border-radius 4px
            color rgba(255, 255, 255, 1)
            font-size 26px
            display flex
            align-items center
            justify-content center
            img
              width 28px
              height 28px
              margin-right 12px
        &-song
          // width 490px
          width 841px
          height fit-content
          // padding-left 106px
          padding-left 0px
          ::v-deep .song-item
            width 841px
            height 104px
            border-bottom none
            .name, .left, .desc
              max-width 500px
              color rgba(255,255,255,0.8)
            .name
              margin-bottom 8px
              .name-txt
                font-size 28px
                display block
                max-width 240px
                white-space nowrap
                overflow hidden
                text-overflow ellipsis
                color rgba(255,255,255,0.8)
              .auther
                font-size 22px
            .song-block-vip
              width 50px
              height auto
        .num
          width 52px
          height 52px
          display flex
          align-items center
          justify-content center
          color #FFFFFFCC
          font-size 36px
          font-family YouSheBiaoTiHei
          margin-left 14px
          margin-right 0px
          font-weight 700
          font-style italic
          &.ordered
            color rgba(219, 174, 106, 1) !important
  .chat-bot-btn
    position absolute
    left 50%
    bottom 40px
    transform translateX(-50%)
    width 240px
    height 64px
    display flex
    align-items center
    justify-content center
    margin-top 30px
    margin-left 0
    /* Styles for "换一批" button */
    background-color: rgba(255, 255, 255, 0.1); /* Example background color */
    backdrop-filter: blur(160px)
    cursor: pointer; /* Add pointer cursor for interaction */
    span
      font-size 22px
      color rgba(255, 255, 255, 1)
  @media screen and (max-width 1200px) and (min-height 1200px)
    // width 640px
    // height 563px
    zoom 0.8
    top auto
    bottom 53vw
.recommend-chat-bg
  width 100vw
  height 100vh
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  background rgba(0, 0, 0, 0.8)
  z-index 6
</style>
