import {
  useActivityAiface,
  useActivityAlert,
  useActivityFreeSongNoTime,
  useActivityKSong,
  useActivityKuaiChang,
  useActivityLowerVip,
  useActivityMic,
  useActivityRecommendSong,
  useActivitySignIn,
  useActivityVip,
  useVipExpire,
  useZeroBuyVip
} from '@/components/modal/global/activity-modal/create'
import { inject } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default function useActivity() {
  const store = useStore()
  const router = useRouter()
  const $activityModal = inject('$activityModal')

  const showActivityModal = (data) => {
    $activityModal.show(data)
  }

  const showActivityAlert = (text) => {
    const $activityAlert = useActivityAlert()
    $activityAlert.show(text)
  }

  const showActivityMic = () => {
    const $activityMic = useActivityMic()
    $activityMic.show()
  }

  const showActivityVip = (payload) => {
    store.dispatch('startCheckLoginStatus')
    const $activityVip = useActivityVip()
    $activityVip.show(payload)
  }

  const showVipExpire = (data) => {
    const $vipExpire = useVipExpire()
    $vipExpire.show(data)
  }

  const showActivityRecommendSong = (data) => {
    const $useActivityRecommendSong = useActivityRecommendSong()
    $useActivityRecommendSong.show(data)
  }

  const showActivitySignIn = () => {
    const $activitySignIn = useActivitySignIn({
      router,
    })
    $activitySignIn.show()
  }

  const showActivityZerobuyVip = (unionid) => {
    store.dispatch('zerobuy/startCheckZerobuyStatus', unionid)
    const $activityZerobuyVip = useZeroBuyVip()
    $activityZerobuyVip.show()
  }

  const showActivityKSong = () => {
    const $activityKSong = useActivityKSong()
    $activityKSong.show()
  }

  const showActivityKuaiChang = (data) => {
    const $activityKuaiChang = useActivityKuaiChang()
    return $activityKuaiChang.show(data)
  }

  const showActivityFreeSongNoTime = () => {
    const $activityFreeSongNoTime = useActivityFreeSongNoTime()
    $activityFreeSongNoTime.show()
  }

  const showActivityAiface = () => {
    const $activityAiface = useActivityAiface()
    $activityAiface.show()
  }

  const showActivityLowerVip = () => {
    const $activityLowerVip = useActivityLowerVip()
    $activityLowerVip.show()
  }

  return {
    showActivityModal,
    showActivityAlert,
    showActivityMic,
    showActivityVip,
    showVipExpire,
    showActivityRecommendSong,
    showActivitySignIn,
    showActivityZerobuyVip,
    showActivityKSong,
    showActivityKuaiChang,
    showActivityFreeSongNoTime,
    showActivityAiface,
    showActivityLowerVip,
  }
}