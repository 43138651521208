import { useActivityRetainVip, useActivityRetainVipC } from '@/components/modal/global/activity-modal/create';

// import createRetainModal from '@/components/modal/global/retain-modal/create';
// import RetainDialog from '@/components/modal/global/retain-abTest/create';
import store from '@/store';
import { computed } from 'vue';

export default function useRetainModal() {
    // const store = useStore()
    const abTestVersionTag = computed(() => store.state.abTest.abTestVersionTag) 
    // const $retainModal = createRetainModal()
    // const retainModalInstance = ref(null)

    const showRetainModal = () => {
        // retainModalInstance.value = $retainModal.show()
        // 组件调整
        // return showActivityRetainVip()
        console.log('showRetainModal already offline~')
    }

    const showActivityRetainVip = () => {
        // == 'C'
        if(abTestVersionTag.value == 'D'){
            const $activityLowerVipC = useActivityRetainVipC()
            $activityLowerVipC.show()
            return
        }
        const $activityLowerVip = useActivityRetainVip()
        $activityLowerVip.show()
      }
      
    // 返回包含所有函数的对象
    return {
        showRetainModal,
    }
}