<template>
  <svg
    class="order-icon"
    width="72"
    height="73"
    viewBox="0 0 72 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.3807 18.2C38.3807 17.2611 37.6196 16.5 36.6807 16.5C35.7418 16.5 34.9807 17.2611 34.9807 18.2V34.6818H18.2625C17.3236 34.6818 16.5625 35.4429 16.5625 36.3818C16.5625 37.3207 17.3236 38.0818 18.2625 38.0818H34.9807V54.8C34.9807 55.7389 35.7418 56.5 36.6807 56.5C37.6196 56.5 38.3807 55.7389 38.3807 54.8V38.0818H54.8625C55.8014 38.0818 56.5625 37.3207 56.5625 36.3818C56.5625 35.4429 55.8014 34.6818 54.8625 34.6818H38.3807V18.2Z"
      fill="#5500C3"
    />
  </svg>
</template>

<script>
export default {
  name: 'OrderIcon',
}
</script>

<style lang="stylus" scoped>
.order-icon
  width 72px
  height 72px
</style>
