<template>
  <CommonModal ref="root" :onCancel="handleCancel" class="trysing-vip-v">
    <div class="trysing-vip-v-qrcode">
      <FullScreenVipBg class="bg" />

      <div @click="handleCloseModal" class="close">
        <FullScreenVipClose />
      </div>

      <div class="pkg" v-if="pkgArr.length">
        <TrySingQrcodePkg
          :pkg="pkgArr"
          :selected="currSelected"
          @choose="handleClickPackage"
        />
        <!-- <div class="pkg-item" @click="handleClickPackage(0)"></div> -->
        <!-- <div class="pkg-item" @click="handleClickPackage(1)"></div> -->
      </div>

      <div class="trysing-vip-title">
        <div class="header-img">
          <img :src="singerHeader" alt="" />
        </div>
        <div class="header-text">
          <p>试唱结束</p>
          <p>
            <span>{{ singerName }}: </span>
            <span>{{ headerText }}</span>
          </p>
        </div>
      </div>

      <div class="m-vip-tips">
        <p>
          <span>{{ selectedItem.title }}</span>
        </p>
        <p>微信扫码支付</p>
        <p>有效期至-{{ expirationDate }}</p>
        <div class="m-vip-tips-qrcode">
          <img :src="qrCodeURL" />
        </div>
      </div>
    </div>
  </CommonModal>
</template>

<script>
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
// import useRetainModal from '@/composables/useRetainModal'
import FullScreenVipBg from '@/components/vue-img-kit/fullScreenVipBg.vue'
import FullScreenVipClose from '@/components/vue-img-kit/fullScreenVipClose.vue'
import { sendLog } from '@/directives/v-log/log'
import { clearVipQueryPolling, getVipPkgQr, getVipQuery } from '@/service/vip'
import store from '@/store'
import { checkLandscapeOrPortrait } from '@/utils/device'
import { getUserRoleTag } from '@/utils/historyCache'
import { addDays, format } from 'date-fns'
import get from 'lodash/get'
import {
  computed,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  toRefs,
} from 'vue'
import TrySingQrcodePkg from './pkg.vue'

export default {
  name: 'TrySingVipZdQrcode',
  components: {
    CommonModal,
    TrySingQrcodePkg,
    FullScreenVipBg,
    FullScreenVipClose,
  },
  props: {
    fr: {
      type: Number,
      default: 0,
    },
    closeCallback: {
      type: Function,
      default: null,
    },
    singerName: {
      type: String,
      default: '',
    },
    singerHeader: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { fr, closeCallback, singerName } = toRefs(props)
    const unionid = computed(() => store.state.userInfo.unionid)
    const { getQRCodeURL } = useQRCode()
    const root = ref(null)
    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const isNewUser = ref(0) // 1 新用户 2 老用户

    const currSelected = ref(1)
    // const pkg = [
    //   {
    //     id: 22,
    //     value: '1年',
    //   },
    //   {
    //     id: 18, // 需调整
    //     value: '2年',
    //   },
    // ]
    const pkgArr = ref([
      {
        title: '1年',
        desc: '',
        dayFee: '0.21',
        price: '￥79',
        oldPrice: '￥399',
        pkgid: 22,
        limitTimer: false,
        id: 0,
        days: 365,
      },
      {
        title: '2年',
        desc: '限时秒杀',
        dayFee: '0.22',
        price: '￥159',
        oldPrice: '￥699',
        pkgid: 18,
        limitTimer: true,
        id: 1,
        days: 730,
      },
      {
        title: '30天',
        desc: '',
        dayFee: '0.97',
        price: '￥39',
        oldPrice: '￥39',
        pkgid: 1,
        limitTimer: false,
        id: 2,
        days: 30,
      },
    ])

    const end_time = computed(() => store.state.vipInfo.end_time)

    const isVip = computed(() => !!end_time.value)

    const userType = computed(() => store.state.userInfo.userType)

    const expirationDate = computed(() => {
      const currentDate = isVip.value ? new Date(end_time.value) : new Date()
      const expire = addDays(currentDate, pkgArr.value[currSelected.value].days)

      return format(expire, 'yyyy.MM.dd')
    })

    const selectedItem = computed(() => {
      return pkgArr.value[currSelected.value]
    })

    const headerTextArr = [
      // 'xxx邀您嗨唱全曲', // 🎙️
      '哇！唱这么好听、一定要唱完整首',
      '是不是只有你在唱歌才这么好听！快来唱完整首吧～',
      '不愧是你，怎么这么好听！想听你唱完整首～', // 🎉
      '天籁之音！快唱完整首吧！',
    ]

    const headerText = computed(() => {
      let res = ''
      const index = Math.floor(Math.random() * headerTextArr.length)
      res = headerTextArr[index]
      // if (res.includes('xxx')) {
      //   if (singerName.value) {
      //     res = headerTextArr[index].replace('xxx', singerName.value)
      //   } else {
      //     res = headerTextArr[Math.floor(Math.random() * headerTextArr.length)]
      //   }
      // } else if (singerName.value) {
      //   res = `${singerName.value}:${res}`
      // }
      return res
    })

    const handleClickPackage = (pkg) => {
      console.log(pkg)
      currSelected.value = pkg.id

      getVipQrcode()
    }

    const getZdVipQrcode = async () => {
      console.log('PAY LOG:', fr.value)
      let from = 1915
      // fr.value === vipLogFrom.get('免费歌曲主动弹-zd')
      //   ? vipLogFrom.get('免费歌曲主动弹-lower-zd')
      //   : fr.value
      //   ? fr.value
      //   : 1901
      try {
        const data = await getVipPkgQr({
          unionid: unionid.value,
          pkg_id: pkgArr.value[currSelected.value].pkgid,
          fr: from,
        })
        const qr = get(data, 'qr', '')
        if (qr) {
          qrCodeURL.value = await getQRCodeURL(qr)
          await getVipQuery(data.order_id)
          store.dispatch('getCarplayInfo')
          root.value.hide()
          clearVipQueryPolling()
        }
      } catch (error) {
        clearVipQueryPolling()
      }
    }

    const getVipQrcode = async () => {
      sendLog({
        event_type: '30000～35000',
        event_name: 30326,
        event_data: {
          str1: '通用',
          str2: `新付费弹窗2`,
          str3: '展示',
          str4: 'show',
          str5: '已登录',
          str7: isNewUser.value === 2 ? '2' : '1',
        },
      })

      getZdVipQrcode()
    }
    const handleCloseModal = () => {
      clearVipQueryPolling()
      store.dispatch('getCarplayInfo')
      root.value.hide()
      sendStayTimeLog()

      closeCallback.value && closeCallback.value()
    }
    const handleCancel = () => {
      // 点击其他区域关闭弹窗时，也去刷一次
      store.dispatch('getCarplayInfo')
      sendStayTimeLog()

      closeCallback.value && closeCallback.value()
    }

    onBeforeMount(getVipQrcode)

    // 记录弹窗显示时长
    let startDate = 0
    const sendStayTimeLog = () => {
      // console.log('sendStayTimeLog', startDate)
      // 停留时长上报
      let stayTime = Math.floor((Date.now() - startDate) / 1000)
      sendLog({
        event_type: '30000～35000',
        event_name: 30342,
        event_data: {
          str1: '试唱页',
          str2: '试唱接受后支付弹框',
          str3: stayTime,
          str4: '时长',
          str5: '已登录',
          str7: isNewUser.value === 2 ? '2' : '1',
        },
      })
    }

    onMounted(() => {
      isNewUser.value = getUserRoleTag()
      // console.log(tag)

      if (window) window.addEventListener('resize', getBrowserSize)
    })

    onUnmounted(() => {
      if (window) window.removeEventListener('resize', getBrowserSize)
    })

    const getBrowserSize = () => {
      console.log('resize!!!', checkLandscapeOrPortrait())
      let isLandscape = checkLandscapeOrPortrait() === 'landscape'
      console.log(isLandscape)
      // 非横屏关闭当前弹窗
      if (!isLandscape) {
        handleCloseModal()
      }
    }

    return {
      qrCodeURL,
      root,
      pkgArr,
      currSelected,
      selectedItem,
      headerText,
      expirationDate,
      handleCancel,
      handleCloseModal,
      handleClickPackage,
    }
  },
}
</script>

<style lang="stylus" scoped>
.trysing-vip-v
  ::v-deep .thunder-modal-background
    background none

  .trysing-vip-title
    position absolute
    top 256px
    left 107px
    width 1353px
    height 254px
    display flex
    justify-content center
    // align-items center
    .header-img
      width 254px
      height 254px
      border-radius 40px
      border: 1px solid;
      border-image-source: linear-gradient(141.47deg, #FFEBB6 4.52%, #B58400 50.45%, #FFEBB6 95.48%);
      overflow hidden
      img
        width 100%
        height 100%
    .header-text
      flex 1
      display flex
      // justify-content center
      padding-top 10px
      flex-direction column
      p:nth-child(1)
        font-size 50px
        height 59px
        line-height 59px
        font-weight 400
        color rgba(217, 217, 217, 1)
        padding-left 57px
      p:nth-child(2)
        font-size 50px
        // height 59px
        // line-height 59px
        // background: linear-gradient(270deg, #DBAE6A 0%, #FFEBB6 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        padding-left 57px
        margin-top 10px
        max-width 1050px
        // 超出部分自动换行
        overflow-wrap: break-word
        white-space: normal;
        line-height: 1.2;
        span:nth-child(1)
          color rgba(217, 217, 217, 1)
        span:nth-child(2)
          background: linear-gradient(270deg, #DBAE6A 0%, #FFEBB6 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
.trysing-vip
  &-v-qrcode
    position relative
    width 100vw
    height 100vh
    background rgba(0, 0, 0, 0.5)
    // background url('') no-repeat
    // background-size 100% 100%
    // background-position center
    .bg
      position absolute
      top 0
      left 0
      z-index 0
    @media screen and (max-width 1200px)
      zoom 1
    .pkg
      position absolute
      bottom 106px
      left 278px
      width 980px
      height 386px
      display flex
      .pkg-item
        width 280px
        height 364px
        background #FFFFFF
    .close
      position absolute
      top 53px
      right 67px
      left unset!important
      width 70px
      height 70px
    .m-vip-tips
      width 336px
      height 504px
      position absolute
      bottom 30px
      right 70px
      display flex
      flex-direction column
      align-items center
      p
        &:nth-child(1)
          // font-size 22px
          font-size 50px
          font-weight 500
          // color rgba(136, 55, 0, 1)
          color rgba(220, 163, 79, 1)
          span
            font-size 50px
        &:nth-child(2)
          font-size 34px
          font-weight 400
          color rgba(255, 255, 255, 0.8)
          margin 24px 0 14px
        &:nth-child(3)
          font-size 26px
          font-weight 400
          color rgba(255, 255, 255, 0.6)
          margin-bottom 24px
      &-qrcode
        // position absolute
        // bottom 3px !important
        // left 50%
        // margin-left -70px
        width 240px
        height 240px
        display flex
        justify-content center
        align-items center
        background #FFFFFF
        border-radius 0
        img
          width 230px
          height 230px
          border-radius 0
</style>
