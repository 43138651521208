import { getABInfo } from '@/service/device';
import { getABTestTag } from '@/utils/historyCache';
import get from 'lodash/get';

const state = {
  abTestVersion: '',
  abTestVersionTag: '' // A/B Test 版本 默认空也为A线上
}

const getters = {}

const actions = {
  setABTestVersion({ commit }) {
    const abTestVer = getABTestTag()
    commit('SAVE_ABTEST_VERSION', abTestVer)
  },
  async setABTestVersionTag({ commit }, data) {
    const abTestVerTag = await getABInfo(data)
    let ver = get(abTestVerTag, 'group', '')
    console.log('abTestVerTag', ver)
    commit('SAVE_ABTEST_VERSION_TAG', ver)
  }
}

const mutations = {
  SAVE_ABTEST_VERSION(state, data) {
    state.abTestVersion = data
  },
  SAVE_ABTEST_VERSION_TAG(state, data) {
    state.abTestVersionTag = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
