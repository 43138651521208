import httpV2 from '@/utils/httpV2';
import { get } from 'lodash';
import config from '../config';
import { getMacAddress } from '../utils/mac';

// export async function getDeviceList() {
//   const res = await http.get(`http://${getAppConfig('host')}:10011/device`)
//   return get(res, 'data', {});
// }

export function getMac() {
  return getMacAddress()
}

/**
 * abtest接口：
  * method：get
  * /stb/v2/abtest
  * query参数：公参 + experiment_id=TestSC1  实验标识ID需要产品提供
 */
export async function getABInfo() {
  console.log('api request getABInfo');
  const res = await httpV2.get('/stb/v2/abtest', {
    params: {
      experiment_id: `${config.abTest}`,
    }
  })
  return get(res, 'data', {});
}