<template>
  <svg
    class="order-icon"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34 17.5C34 16.3954 33.1046 15.5 32 15.5C30.8954 15.5 30 16.3954 30 17.5V29.5H18C16.8954 29.5 16 30.3954 16 31.5C16 32.6046 16.8954 33.5 18 33.5H30V45.5C30 46.6046 30.8954 47.5 32 47.5C33.1046 47.5 34 46.6046 34 45.5V33.5H46C47.1046 33.5 48 32.6046 48 31.5C48 30.3954 47.1046 29.5 46 29.5H34V17.5Z"
        fill="white"
        style="fill: white; fill-opacity: 1"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'OrderWIcon',
}
</script>

<style lang="stylus" scoped>
.order-icon
  width 72px
  height 72px
</style>
