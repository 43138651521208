<template>
  <svg
    v-if="isShowAudition"
    class="audition-play"
    width="51"
    height="28"
    viewBox="0 0 51 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 5C3 2.23858 5.23858 0 8 0H46C48.7614 0 51 2.23858 51 5V23C51 25.7614 48.7614 28 46 28H1.56291C0.833117 28 0.349125 27.2436 0.65495 26.5809L2.63184 22.2977C2.87439 21.7722 3 21.2003 3 20.6215V5Z"
      fill="url(#paint0_linear_7471_48364)"
      style=""
    />
    <path
      d="M3 5C3 2.23858 5.23858 0 8 0H46C48.7614 0 51 2.23858 51 5V23C51 25.7614 48.7614 28 46 28H1.56291C0.833117 28 0.349125 27.2436 0.65495 26.5809L2.63184 22.2977C2.87439 21.7722 3 21.2003 3 20.6215V5Z"
      fill="url(#paint1_linear_7471_48364)"
      style=""
    />
    <path
      d="M26.316 17.724C26.244 18.18 26.136 18.696 25.992 19.272C25.86 19.848 25.74 20.31 25.632 20.658C25.308 21.714 24.798 22.242 24.102 22.242C23.31 22.242 22.632 21.666 22.068 20.514C21.696 19.71 21.396 18.564 21.168 17.076C20.94 15.588 20.754 13.53 20.61 10.902H14.814V9.282H20.556L20.466 5.826H22.032C22.068 7.362 22.098 8.514 22.122 9.282H26.028V10.902H22.176C22.296 13.266 22.458 15.108 22.662 16.428C22.866 17.736 23.13 18.756 23.454 19.488C23.694 19.98 23.916 20.226 24.12 20.226C24.324 20.226 24.498 19.842 24.642 19.074C24.75 18.45 24.852 17.814 24.948 17.166L26.316 17.724ZM24.642 9.048C24.39 8.772 24.048 8.418 23.616 7.986C23.184 7.542 22.83 7.2 22.554 6.96L23.544 5.97C23.808 6.198 24.156 6.522 24.588 6.942C25.02 7.35 25.38 7.71 25.668 8.022L24.642 9.048ZM12.942 10.29C12.63 9.87 12.21 9.342 11.682 8.706C11.154 8.058 10.722 7.548 10.386 7.176L11.718 6.096C12.066 6.468 12.498 6.972 13.014 7.608C13.542 8.232 13.962 8.748 14.274 9.156L12.942 10.29ZM15.228 18.444C14.568 19.104 13.806 19.914 12.942 20.874C12.69 21.162 12.42 21.48 12.132 21.828L11.034 20.622C11.214 20.418 11.34 20.214 11.412 20.01C11.496 19.806 11.538 19.548 11.538 19.236V13.278H9.558V11.55H13.122V18.534C13.35 18.258 13.764 17.784 14.364 17.112L15.228 18.444ZM18.324 18.822C18.528 18.774 18.834 18.714 19.242 18.642C19.542 18.57 19.992 18.474 20.592 18.354L20.7 20.01C19.104 20.346 17.28 20.7 15.228 21.072L14.742 21.162L14.562 19.542L16.776 19.128V14.934H14.886V13.296H20.268V14.934H18.324V18.822ZM34.794 6.852H42.894V13.35H34.794V6.852ZM33.282 19.812H28.584V7.626H33.282V19.812ZM41.31 9.444V8.22H36.342V9.444H41.31ZM31.734 9.3H30.132V18.102H31.734V9.3ZM36.342 10.722V11.982H41.31V10.722H36.342ZM34.308 22.314V14.682H43.416V22.296H41.832V21.486H35.856V22.314H34.308ZM41.832 17.454V16.05H35.856V17.454H41.832ZM35.856 18.768V20.19H41.832V18.768H35.856Z"
      fill="#2B2B49"
      style="
        fill: #2b2b49;
        fill: color(display-p3 0.1686 0.1686 0.2863);
        fill-opacity: 1;
      "
    />
    <defs>
      <linearGradient
        id="paint0_linear_7471_48364"
        x1="51"
        y1="14"
        x2="3.00001"
        y2="14"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#B2D8FF"
          style="
            stop-color: #b2d8ff;
            stop-color: color(display-p3 0.6997 0.8473 1);
            stop-opacity: 1;
          "
        />
        <stop
          offset="1"
          stop-color="#CAB2FD"
          style="
            stop-color: #cab2fd;
            stop-color: color(display-p3 0.7917 0.6971 0.9913);
            stop-opacity: 1;
          "
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7471_48364"
        x1="51"
        y1="14"
        x2="3.00001"
        y2="14"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#A8D3FF"
          style="
            stop-color: #a8d3ff;
            stop-color: color(display-p3 0.6606 0.8274 1);
            stop-opacity: 1;
          "
        />
        <stop
          offset="1"
          stop-color="#CBB2FF"
          style="
            stop-color: #cbb2ff;
            stop-color: color(display-p3 0.7963 0.6999 1);
            stop-opacity: 1;
          "
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'AuditionPlayIcon',
  props: {
    isShowAudition: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="stylus" scoped>
.audition-play
  position absolute
  top -7px
  right -37px
  width 51px !important
  height 28px !important
</style>
