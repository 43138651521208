import { getOpenscreenInfo } from '@/service/activity';

const state = {
  openscreen1: '',
  openscreen2: ''
}

const getters = {}

const actions = {
  async setOpenscreenInfo({ commit }) {
    const res = await getOpenscreenInfo()
    commit('SAVE_OPENSCREEN', res)
  },
}

const mutations = {
  SAVE_OPENSCREEN(state, data) {
    state.openscreen1 = data.openscreen1
    state.openscreen2 = data.openscreen2
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
