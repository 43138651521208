// import http from '@/utils/http'
import config from '@/config'
import store from '@/store'
import http from '@/utils/httpV2'
import store2 from 'store2'

export async function getCarplayInfo() {
  // /stb/carplay/info
  const _ab = `${config.abTest}-${store.state.abTest.abTestVersionTag}`
  const res = await http.get('/stb/v2/info', {
    params: {
      session: store2('_boot_id'),
      mac_id: store.state.macAddress,
      _abtest: _ab
    }
  })
  return res
  
  // let payQr = get(res, 'data.pay_qr', '')
  // let loginQr = get(res, 'data.login_qr', '')
  // const _ab = `${config.abTest}-${store.state.abTest.abTestVersionTag}`
  // if (payQr) {
  //   payQr = `${payQr}&_abv=${_ab}`
  // }
  // if (loginQr) {
  //   loginQr = `${loginQr}&_abv=${_ab}`
  // }
  // // console.log('getCarplayInfo', res)
  // return {
  //   ...res,
  //   data: {
  //     ...res.data,
  //     pay_qr: payQr,
  //     login_qr: loginQr,
  //   }
  // };
}

export async function getAIface(unionid) {
  const res = await http.get(`/stb/v2/aiface?unionid=${unionid}`)
  return res;
}
