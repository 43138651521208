<!-- _ab: `${config.abTest}-${store.state.abTest.abTestVersionTag}` -->

<template>
  <CommonModal ref="root" :onCancel="handleCancel">
    <div class="retain-vip-modal-content">
      <img @click="handleCloseModal" class="close" :src="icons.close" />
      <div class="retain-vip-modal-content-main">
        <p>
          <img class="main-left" :src="icons.left" alt="" />
          <img class="main-right" :src="icons.right" alt="" />
        </p>
        <div class="main-code">
          <img class="main-code-tip" :src="icons.tip" alt="" srcset="" />
          <div class="main-code-qr">
            <img :src="qrCodeURL" alt="" srcset="" />
            <span>{{ isLogin ? '微信扫码支付' : '微信扫码登录支付' }}</span>
          </div>
        </div>

        <!-- 倒计时 01:59:59 -->
        <div class="main-countdown">
          <span>{{ countdown }}</span>
        </div>
      </div>
      <div class="main-bottom">
        <img :src="icons.bottom" alt="" srcset="" />
      </div>
    </div>
  </CommonModal>
</template>

<script>
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
import { sendLog } from '@/directives/v-log/log'
import { getCarplayInfo } from '@/service/carplay-info'
import { clearVipQueryPolling, getVipPkgQr, getVipQuery } from '@/service/vip'
import store from '@/store'
import { getUserRoleTag } from '@/utils/historyCache'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  toRefs,
} from 'vue'

export default {
  name: 'lowerVipModal',
  components: {
    CommonModal,
  },
  props: {
    closeCallback: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const { closeCallback } = toRefs(props)
    const unionid = computed(() => store.state.userInfo.unionid)
    const { getQRCodeURL } = useQRCode()
    const root = ref(null)
    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const isNewUser = ref(false)
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const userType = computed(() => store.state.userInfo.userType)

    const icons = {
      close:
        'https://qncweb.ktvsky.com/20240222/other/bc74bf68be3006af39e16fd896cf03b6.png',
      left: 'https://qncweb.ktvsky.com/20250225/vadd/fe2f4ab0005ea525d3e9b05941219080.png',
      right:
        'https://qncweb.ktvsky.com/20250225/vadd/611a4c25f602297bbf1881284c328470.png',
      tip: 'https://qncweb.ktvsky.com/20250225/vadd/e60ad60a022041abfca76d971b19242c.png',
      bottom:
        'https://qncweb.ktvsky.com/20250225/vadd/f7ec981a0ea205e773c1df93b31141f3.png',
      timer:
        'https://qncweb.ktvsky.com/20250226/vadd/788b992b710cb41d094c0d8893667807.png',
    }

    const countdown = ref('01:59:59')
    let timer = null

    const startCountdown = () => {
      let totalSeconds = 2 * 3600 - 1 // 1小时59分59秒
      timer = setInterval(() => {
        if (totalSeconds < 0) {
          clearInterval(timer)
          handleCloseModal()
          return
        }

        const hours = Math.floor(totalSeconds / 3600)
        const minutes = Math.floor((totalSeconds % 3600) / 60)
        const seconds = totalSeconds % 60

        countdown.value = `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

        totalSeconds--
      }, 1000)
    }

    const getZdVipQrcode = async () => {
      try {
        const data = await getVipPkgQr({
          unionid: unionid.value,
          pkg_id: 22,
          fr: 1899,
        })
        const qr = get(data, 'qr', '')
        if (qr) {
          qrCodeURL.value = await getQRCodeURL(qr)
          await getVipQuery(data.order_id)
          store.dispatch('getCarplayInfo')
          root.value.hide()
          clearVipQueryPolling()
        }
      } catch (error) {
        clearVipQueryPolling()
      }
    }

    const getMobileVipQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const qrCodeData = await getQRCodeURL(
          `${data.pay_qr}&log=${1898}&ut=${userType.value}`
        )
        console.log('getVipQrcode qrCodeData:', qrCodeData.value)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    const getVipQrcode = async () => {
      sendLog({
        event_type: '30000～35000',
        event_name: isLogin.value ? 30324 : 30323,
        event_data: {
          str1: '通用',
          str2: `新挽留弹窗${isLogin.value ? '2' : '1'}`,
          str3: '展示',
          str4: 'show',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: isNewUser.value === 2 ? '2' : '1',
        },
      })

      if (isLogin.value) {
        getZdVipQrcode()
        return
      }
      getMobileVipQrcode()
    }

    const handleCloseModal = () => {
      clearVipQueryPolling()
      store.dispatch('getCarplayInfo')
      root.value.hide()

      closeCallback.value && closeCallback.value()
    }

    const handleCancel = () => {
      // 点击其他区域关闭弹窗时，也去刷一次
      store.dispatch('getCarplayInfo')

      closeCallback.value && closeCallback.value()
    }

    onBeforeMount(getVipQrcode)

    onMounted(() => {
      isNewUser.value = getUserRoleTag()
      startCountdown()
    })

    onBeforeUnmount(() => {
      if (timer) {
        clearInterval(timer)
      }
    })

    return {
      qrCodeURL,
      root,
      icons,
      isLogin,
      handleCancel,
      handleCloseModal,
      countdown,
    }
  },
}
</script>

<style lang="stylus" scoped>
.retain-vip-modal
  &-content
    position relative
    width 1100px
    height 730px
    padding-top 0 !important
    background url('https://qncweb.ktvsky.com/20250225/vadd/088b2826d7c90fc29af0acab978c3a52.png') no-repeat
    background-size 100% 100%
    background-position center
    @media screen and (max-width 1200px)
      zoom 0.8
    .close
      position absolute
      top 30px
      right 30px
      left unset!important
      width 40px
      height 40px
    &-main
      margin 70px 130px 50px 130px
      p
        display: flex
        margin-top: 65px
        align-items: center
        justify-content: space-between
        .main-left
          width 150px
          height 150px
          margin-right 20px
          margin-top: 70px
        .main-right
          height 115px
          margin-top: 70px
      .main-code
        display: flex
        justify-content: space-between
        &-tip
          width 494px
          height 316px
          margin-top 27px
        &-qr
          width 252px
          height 306px
          margin-top 27px
          background rgba(255, 255, 255, 1)
          border-radius 12px
          text-align center
          img
            width 224px
            height 225px
            margin 14px auto
          span
            display inline-block
            font-size 28px
      .main-countdown
        width 170px
        height 52px
        background url('https://qncweb.ktvsky.com/20250226/vadd/788b992b710cb41d094c0d8893667807.png') no-repeat
        background-size 100% 100%
        background-position center
        position absolute
        top: 36px;
        right: 163px;
        text-align: center;
        line-height: 42px;
        font-size: 22px;
        color: #fff;
        //数字间距变大

    .main-bottom
      width 922px
      height 79px
      margin 0 auto
      img
        width 100%
        height 100%
</style>
