<template>
  <SecContainer title="猜你会唱" class="sec-gusse-sing" :class="from">
    <template #header>
      <div
        v-if="!showAllData"
        class="sec-gusse-sing-change"
        @click.stop="handlechangePagenation"
      >
        <span>换一批</span>
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.8">
            <path
              d="M34.5 13.5L31.5 18"
              stroke="#1D1D1F"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.49985 18L1.49985 22.5"
              stroke="#1D1D1F"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M31.5 18C31.5 10.5442 25.4558 4.5 18 4.5C14.1859 4.5 10.7412 6.08172 8.28608 8.625M4.5 18C4.5 25.4558 10.5442 31.5 18 31.5C21.6417 31.5 24.9467 30.0581 27.375 27.7139"
              stroke="#1D1D1F"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </div>
    </template>
    <div class="sec-gusse-sing-list">
      <SongItem
        :renderType="renderType"
        :isStickButton="isStickButton"
        className="sec-gusse-sing-list-item"
        v-for="(songItem, ind) in guessSongShowList"
        :key="ind"
        :songItem="songItem"
        :log-from="{
          song_list_source: song_list_source
            ? song_list_source
            : isGuessSongList && songItem.sing_cnt
            ? 5
            : 9,
          str1: pageRoute,
        }"
        :ponitActionLog="ponitActionLog"
        @singer-click="handleClickSinger"
      />
    </div>
  </SecContainer>
</template>

<script>
import SecContainer from '@/components/section-container/index.vue'
import SongItem from '@/components/song-item/index.vue'
import { sendLog } from '@/directives/v-log/log'
import { computed, onMounted, ref, toRefs, watch } from 'vue'
import { useStore } from 'vuex'

// function generateGuessSongListOld(oftenSingList, top50List) {
//   // “唱过的歌”和“热唱top100”交替展示（2个唱过的歌 + 1个热唱循环）
//   let guessSongList = []
//   let oftenSingIndex = 0
//   let top50Index = 0

//   while (
//     oftenSingIndex < oftenSingList.length &&
//     top50Index < top50List.length
//   ) {
//     guessSongList.push(oftenSingList[oftenSingIndex])
//     oftenSingIndex++

//     if (oftenSingIndex < oftenSingList.length) {
//       guessSongList.push(oftenSingList[oftenSingIndex])
//       oftenSingIndex++
//     }

//     guessSongList.push(top50List[top50Index])
//     top50Index++
//   }

//   // 将剩余的歌曲添加到列表中
//   guessSongList = guessSongList.concat(oftenSingList.slice(oftenSingIndex))
//   guessSongList = guessSongList.concat(top50List.slice(top50Index))

//   return guessSongList
// }

export default {
  name: 'GuessSongList',
  components: {
    SongItem,
    SecContainer,
  },
  props: {
    // 渲染形态
    renderType: {
      default: 'list', // list:列表形态 block：块状形态
      type: String,
    },
    // 是否显示置顶按钮
    isStickButton: {
      default: true,
      type: Boolean,
    },
    pageSize: {
      default: 6,
      type: Number,
    },
    showAllData: {
      default: false,
      type: Boolean,
    },
    pageRoute: {
      default: '1', // 1：首页 2：二级页面 3：mv页
      type: String,
    },
    ponitActionLog: {
      default: () => {
        // 点歌位置上报 - 10000~50000
        return {
          event_type: '10000~50000',
          event_name: 10017,
          event_data: {
            str1: '首页',
            str2: '猜你会唱',
            str3: '歌曲列表',
            str4: 'click',
          },
        }
      },
      type: Object,
    },
    song_list_source: {
      default: 0,
      type: Number,
    },
    from: {
      type: String,
    },
  },
  emits: ['singer-click'],
  setup(props, { emit }) {
    const store = useStore()
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const oftenSingList = computed(() => store.state.oftenSing.oftenSingList)
    const isGuessSongList = computed(
      () => isLogin.value && oftenSingList.value.length
    )
    const top50List = computed(() => store.state.oftenSing.top50)
    const { pageSize, showAllData } = toRefs(props)
    let page = 1
    let guessSongShowList = ref([])

    const generateGuessSongList = (oftenSingList, top50List) => {
      return oftenSingList.concat(top50List)
    }

    const guessSongShowListUpdate = () => {
      let guessSongList = isGuessSongList.value
        ? generateGuessSongList(oftenSingList.value, top50List.value)
        : top50List.value

      if (showAllData.value) {
        guessSongShowList.value = guessSongList
        page = 1 // 重置 page 为 1
        return
      }

      let startIndex = (page - 1) * pageSize.value
      let endIndex = page * pageSize.value

      if (startIndex >= guessSongList.length) {
        guessSongShowList.value = []
        page = 1 // 重置 page 为 1
      } else {
        guessSongShowList.value = guessSongList.slice(
          startIndex,
          Math.min(endIndex, guessSongList.length)
        )
      }

      if (guessSongShowList.value.length === 0) {
        page = 1
        startIndex = 0
        endIndex = pageSize.value
        guessSongShowList.value = guessSongList.slice(
          startIndex,
          Math.min(endIndex, guessSongList.length)
        )
      }
    }

    const handlechangePagenation = () => {
      if (!oftenSingList.value.length && !top50List.value.length) return
      ++page
      guessSongShowListUpdate()
      sendLog({
        event_type: '10000~50000',
        event_name: 10018,
        event_data: {
          str1: '首页',
          str2: '猜你会唱列表',
          str3: '换一换',
          str4: 'click',
        },
      })
    }

    const resetPage = () => {
      page = 1
      guessSongShowListUpdate()
    }

    const handleClickSinger = (data) => {
      emit('singer-click', data)
    }

    watch(isLogin, () => {
      resetPage()
    })

    watch(oftenSingList, () => {
      resetPage()
    })

    watch(top50List, () => {
      resetPage()
    })

    watch(pageSize, () => {
      resetPage()
    })

    onMounted(() => {
      resetPage()
    })

    return {
      isLogin,
      isGuessSongList,
      guessSongShowList,
      handlechangePagenation,
      handleClickSinger,
    }
  },
}
</script>

<style lang="stylus" scoped>
.sec-gusse
  &-sing
    margin-bottom 40px
    ::-webkit-scrollbar
      display none
    ::v-deep .section-container-header-title
      color rgba(29, 29, 31, 0.9)
      font-size var(--font-size-large)
      font-weight bold

    &-list
      min-height 400px
      display grid
      grid-template-columns repeat(3, 560px)
      justify-content space-between
      position relative
      ::v-deep .song-item
        height 137px
      @media screen and (max-width 1200px) and (min-height 1200px)
        grid-template-columns repeat(3, 344px)
        min-height 304px
        overflow hidden
        ::v-deep .song-block
          width 344px
          height 140px
          margin-bottom 24px
          .name
            font-size 26px
          .desc
            font-size 20px
    &-change
      width 154px
      display flex
      align-items center
      justify-content center
      span
        width auto
        height 40px
        margin-right 5px
        color rgba(29, 29, 31, 0.8)
        font-size var(--font-size-32)
      svg
        width 36px
        height 36px
        margin-top 8px
.index
  :deep(.section-container-list)
    width 1760px
    display flex
    align-items center
    justify-content center
    @media screen and (max-width 1200px) and (min-height 1200px)
      width 100%
  .sec-gusse-sing-list
    width 100%
    border-radius var(--border-radius-large)
    padding 20px 0
    gap 0
    grid-template-columns repeat(3, 1fr)
    background #fff
    @media screen and (max-width 1200px) and (min-height 1200px)
      grid-template-columns repeat(2, 536px)
    :deep(.song)
      padding 0px !important
      .song-item
        padding 0px 36px !important
        width 100%
        height 135px !important
        background transparent
        border none !important
        .left
          max-width 70%
          .author
            // width 100px
            // max-width 100px
            max-width 130px
          .flag
            padding-left 6px
            margin-left 6px
        .right
          margin-right 0px
          .immediate-play
            margin-right 28px !important
            .iplay
              width 64px !important
              height 64px !important
          .order-play
            svg
              width 64px !important
              height 64px !important
</style>
