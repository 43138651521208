<template>
  <div class="image-gallery" :class="browserType">
    <div class="image-gallery__top">
      <img
        class="image-gallery__image"
        :src="navList[0].image[0]"
        alt="Image 1"
        @click="handleClick(navList[0])"
      />
      <img
        class="image-gallery__image"
        :src="navList[1].image[0]"
        alt="Image 2"
        @click="handleClick(navList[1])"
      />
    </div>
    <div class="image-gallery__bottom">
      <img
        class="image-gallery__image"
        :src="navList[2].image[0]"
        alt="Image 3"
        @click="handleClick(navList[2])"
      />
      <img
        class="image-gallery__image"
        :src="navList[3].image[0]"
        alt="Image 3"
        @click="handleClick(navList[3])"
      />
    </div>
  </div>
</template>

<script>
import { useShareBrowserSize } from '@/composables/sharedComposable'
import { ref } from 'vue'

export default {
  name: 'NavList',
  emit: ['click-nav'],
  setup(_, { emit }) {
    const { browserType } = useShareBrowserSize()

    let navList = ref([
      {
        text: '全部歌手',
        en: '', // 王心凌、周杰伦、薛之谦、张学友...
        image: [
          'https://qncweb.ktvsky.com/20250305/vadd/d03a5c8423230dbef80c92985ee0d97d.png',
          // 'https://qncweb.ktvsky.com/20241127/vadd/39b59d6fc86ee61be7dc213cfea80fa1.png',
          // 'https://qncweb.ktvsky.com/20241127/vadd/39b59d6fc86ee61be7dc213cfea80fa1.png',
        ],
        pathName: 'singer',
        isSupport: true,
        type: 'page',
      },
      {
        text: '唱过的歌',
        en: '',
        image: [
          'https://qncweb.ktvsky.com/20250305/vadd/0e937e70f5c7ef9555d819c6dab158af.png',
        ],
        pathName: 'singing',
        isSupport: true,
        type: 'page',
      },
      {
        text: '换脸mv',
        en: '',
        image: [
          'https://qncweb.ktvsky.com/20250305/vadd/a20a6c960fe69be703a3881d8a032260.png',
          // 'https://qncweb.ktvsky.com/20241127/vadd/203fbb4865720a8797ec0b2995e53026.png',
          // 'https://qncweb.ktvsky.com/20241127/vadd/203fbb4865720a8797ec0b2995e53026.png',
        ],
        pathName: 'aiface',
        isSupport: true,
        type: 'custom',
      },
      {
        text: '定制歌单',
        en: '',
        image: [
          'https://qncweb.ktvsky.com/20250313/vadd/f19c7d81b5d0ebcd0e06b7fb52ea2b37.png',
          // 'https://qncweb.ktvsky.com/20250305/vadd/6d7b02b8a1e21a8f1d0e233a08b28da8.png',
          // 'https://qncweb.ktvsky.com/20241127/vadd/7473808c0a9077c2f0960ee92824cec1.png',
          // 'https://qncweb.ktvsky.com/20241127/vadd/7473808c0a9077c2f0960ee92824cec1.png',
        ],
        pathName: 'songList',
        isSupport: true,
        type: 'page',
      },
    ])

    const handleClick = (e) => {
      // 处理点击事件
      console.log('Image clicked!')
      emit('click-nav', e)
    }

    return {
      navList,
      handleClick,
      browserType,
    }
  },
}
</script>

<style lang="stylus" scoped>
.image-gallery
  display flex
  flex-direction column
  align-items center

  .image-gallery__top
    display flex
    justify-content space-between
    width 100%

  .image-gallery__bottom
    margin-top 20px
    display flex
    justify-content space-between
    width 100%

  .image-gallery__image
    cursor pointer
    transition all 0.3s

  .image-gallery__image:hover
    transform scale(1.05)

.vertical
  display flex !important
  flex-direction row
  justify-content space-between
  margin-bottom 50px
  img
    height 186px
  .image-gallery__top
    display flex
    justify-content space-between
    width fit-content
    img:first-child
      margin-right 24px
  .image-gallery__bottom
    margin 0px
    width fit-content
    img:first-child
      margin-right 24px
</style>
