import http from '@/utils/httpV2'
import get from 'lodash/get'
import httpV2 from '@/utils/httpV2'

export async function getSongM3U8(songid) {
  if (!songid) return {}
  const result = await http.get('/stb/v2/detail/song', {
    params: {
      songid
    }
  })
  return {
    ...get(result, 'data', {}),
    msg: get(result, 'errmsg', ''),
    error: result === undefined
  }
}

export async function getTrySongM3U8(songid) {
  if (!songid) return {}
  const result = await http.get('/stb/v2/detail/song/preview', {
    params: {
      songid
    }
  })
  return {
    ...get(result, 'data', {}),
    msg: get(result, 'errmsg', ''),
    error: result === undefined
  }
}

export async function getSongToken() {
  // if (!songid) return {};
  const result = await http.get('/stb/v2/detail/song/token')
  return get(result, 'data', {})
}

export async function getSongLrc(songid, lrc) {
  if (!songid) return {}
  const result = await http.get('/stb/v2/detail/song/lrc', {
    params: {
      songid,
      lrc
    }
  })
  return {
    ...get(result, 'data', {}),
    msg: get(result, 'errmsg', ''),
    error: result === undefined
  }
}

// 缺歌反馈
export async function feedback({ type,description, _isvip }) {
  const res = await httpV2.post(`/stb/v2/feedback?type=${type}&description=${description}&_isvip=${_isvip}`)
  return get(res, 'errcode', '');
}