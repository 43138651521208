<template>
  <CommonModal ref="root" :onCancel="handleCancel">
    <div
      class="vip-loggedin-modal-new-content"
      :class="[fromType, isExpired && 'expired']"
    >
      <div class="close" @click="handleCloseModal"></div>
      <div class="vip-packages">
        <div
          class="vip-packages-item"
          v-for="item in packages"
          :key="item.id"
          :class="item.id === selectItem.id && 'active'"
          @click="handleClickItem(item)"
        >
          <div v-if="item.tips" class="tips" :class="item.isOrange && 'orange'">
            {{ item.tips }}
          </div>
          <div class="days">{{ item.title }}</div>
          <div class="day-price">
            ¥&nbsp;&nbsp;<span>{{ formatValue(item.day_fee) }}</span
            >/天
          </div>
          <div class="price">
            <span>¥{{ formatValue(item.fee) }}</span>
            <span v-if="item.old_fee !== item.fee" class="origin"
              >¥{{ formatValue(item.old_fee) }}</span
            >
          </div>

          <!-- 限时：01:59:59 -->
          <div class="pkg-limit-time" v-if="item.id === 4">
            限时：{{ formatTime(countdown) }}
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="pay-info">
        <div class="left">
          <div v-if="packages.length == 3" class="price">
            <span>{{ formatValue(selectItem.fee) }}</span
            >元
          </div>
          <div v-else class="price" v-html="formatPayTitle"></div>
          <h3>微信扫码支付</h3>
          <p>有效期至-{{ expirationDate }}</p>
        </div>
        <div class="code">
          <img :src="qrCodeURL" alt="" />
        </div>
      </div>
    </div>
  </CommonModal>
</template>

<script setup>
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
import useRetainModal from '@/composables/useRetainModal'
import { sendLog } from '@/directives/v-log/log'
import {
  clearVipQueryPolling,
  getVipPkg,
  getVipPkgQr,
  getVipQuery,
} from '@/service/vip'
import store from '@/store'
import { getRetainModalTag, setRetainModalTag } from '@/utils/historyCache'
import { addDays, format } from 'date-fns'
import get from 'lodash/get'
import {
  computed,
  defineProps,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
} from 'vue'

// Props
const props = defineProps({
  fr: {
    type: Number,
    default: 0,
  },
  logInfo: {
    type: Object,
    default: () => ({}),
  },
  fromType: {
    type: String,
    default: '',
  },
  closeCallback: {
    type: Function,
    default: null,
  },
  isShowRetain: {
    type: Boolean,
    default: true,
  },
})

// Refs
const root = ref(null)
const qrCodeURL = ref(
  'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
)
const packages = ref([])
const selectItem = ref({})

// Computed
const vipInfo = computed(() => store.state.vipInfo)
const isVip = computed(() => !!vipInfo.value.end_time)
const unionid = computed(() => store.state.userInfo.unionid)
const isExpired = computed(() => !isVip.value && vipInfo.value.expire)

const expirationDate = computed(() => {
  if (!selectItem.value.days) return ''
  const currentDate = isVip.value
    ? new Date(vipInfo.value.end_time)
    : new Date()
  return format(addDays(currentDate, selectItem.value.days), 'yyyy.MM.dd')
})

const formatPayTitle = computed(() => {
  const regex = /(\d+)(年|天)/g
  if (!selectItem.value.title) return ''
  const matches = selectItem.value.title.match(regex)
  let formatted = ''
  if (matches) {
    for (let i = 0; i < matches.length; i++) {
      const match = matches[i].match(/(\d+)(年|天)/)
      formatted += `<span>${match[1]}</span>${match[2]}`
      if (i < matches.length - 1) formatted += ' '
    }
  }
  return formatted
})

// Methods
const formatValue = (value) => {
  if (value === undefined || isNaN(value)) return 'N/A'
  return value / 100
}

const getVipQrcode = async () => {
  try {
    let frStr = props.fr
    if (props.fr == 1873) frStr = 1874
    if (props.fr == 1875) frStr = 1876
    if (props.fr == 1878) frStr = 1879
    if (props.fr == 1896) frStr = 1896
    if (props.fr == 1894) frStr = 1894

    const data = await getVipPkgQr({
      unionid: unionid.value,
      pkg_id: selectItem.value.id,
      fr: frStr,
    })

    const qr = get(data, 'qr', '')
    if (qr) {
      const qrCodeData = await useQRCode().getQRCodeURL(qr)
      if (qrCodeData) {
        qrCodeURL.value = qrCodeData
        await getVipQuery(data.order_id)
        store.dispatch('getCarplayInfo')
        root.value.hide()
        clearVipQueryPolling()
      }
    }
  } catch (error) {
    root.value.hide()
    clearVipQueryPolling()
  }
}

const handleCloseModal = async () => {
  await store.dispatch('getCarplayInfo')
  root.value.hide()
  clearVipQueryPolling()

  if (props.isShowRetain && !getRetainModalTag()) {
    useRetainModal().showRetainModal()
    setRetainModalTag()
  }

  sendLog({
    event_type: '10000~50000',
    event_name: 10094,
    event_data: {
      str1: '任意页',
      str2: 'VIP弹窗',
      str3: '关闭弹窗',
      str4: 'click',
    },
  })

  if (isExpired.value) {
    sendLog({
      event_type: '10000~50000',
      event_name: 30299,
      event_data: {
        str1: '通用',
        str2: '续费弹窗-关闭',
        str3: '点击',
        str4: 'click',
      },
    })
  }

  props.closeCallback && props.closeCallback()
}

const handleCancel = () => {
  store.dispatch('getCarplayInfo')
  sendLog({
    event_type: '10000~50000',
    event_name: 10094,
    event_data: {
      str1: '任意页',
      str2: 'VIP弹窗',
      str3: '关闭弹窗',
      str4: 'click',
    },
  })

  if (isExpired.value) {
    sendLog({
      event_type: '10000~50000',
      event_name: 30299,
      event_data: {
        str1: '通用',
        str2: '续费弹窗-关闭',
        str3: '点击',
        str4: 'click',
      },
    })
  }

  props.closeCallback && props.closeCallback()
}

const handleClickItem = (item) => {
  qrCodeURL.value =
    'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
  selectItem.value = item
  getVipQrcode()
}

// timer
const countdown = ref(7200) // 初始值设为2小时 (7200秒)
let timer = null

const formatTime = (seconds) => {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = seconds % 60
  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s
    .toString()
    .padStart(2, '0')}`
}

const startCountdown = () => {
  timer = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--
    } else {
      clearInterval(timer)
    }
  }, 1000)
}

onMounted(() => {
  startCountdown()
})

onUnmounted(() => {
  if (timer) {
    clearInterval(timer)
  }
})

// Lifecycle
onBeforeMount(async () => {
  const res = await getVipPkg(unionid.value)
  packages.value = res.data

  if (packages.value.length === 3) {
    const index = packages.value.findIndex((item) => !!item.tips)
    packages.value[index].isOrange = true
  } else {
    const reversedIndex = packages.value
      .slice()
      .reverse()
      .findIndex((item) => !!item.tips)
    const index = packages.value.length - 1 - reversedIndex
    packages.value[index].isOrange = true
  }

  const active =
    packages.value.find((item) => item.id === res.recommend_pkg) ||
    packages.value[0]
  selectItem.value = active
  getVipQrcode()

  if (props.logInfo?.event_name) {
    sendLog({
      event_type: '10000~50000',
      event_name: props.logInfo.event_name,
      event_data: props.logInfo.event_data,
    })
  }

  if (isExpired.value) {
    sendLog({
      event_type: '10000~50000',
      event_name: 30298,
      event_data: {
        str1: '通用',
        str2: '续费弹窗',
        str3: '展示',
        str4: 'show',
      },
    })
  }
})
</script>

<style lang="stylus" scoped>
.vip-loggedin-modal-new-content
  width 1100px
  height 746px
  border-radius 20px
  background-image url(https://qncweb.ktvsky.com/20250225/vadd/8aa0532f216a21bdf79ff23413d4086c.png) // 新背景图片
  background-size 100% auto
  background-repeat no-repeat
  position relative
  display flex
  flex-direction row
  justify-content space-around
  align-items center
  &.expired
    height 730px
    // background-image url(https://qncweb.ktvsky.com/20240920/vadd/9dbdc9e9c74b268fe89565b20ab0760f.png) // 新过期背景图片
    background-image url(https://qncweb.ktvsky.com/20250225/vadd/8aa0532f216a21bdf79ff23413d4086c.png)
    background-size 100% auto
    background-repeat no-repeat
    // .close
    //   position absolute
    //   top 30px
    //   right 30px
    //   width 32px
    //   height 32px
    //   background-image url(https://qncweb.ktvsky.com/20240920/vadd/new-close-icon.png) // 新关闭图标
    //   background-size 100% auto
    //   background-repeat no-repeat
  .close
    position absolute
    top 55px
    right 25px
    width 40px
    height 40px
  .vip-packages
    display flex
    justify-content center
    width 774px
    height 252px
    position absolute
    left 36px
    // bottom 206px
    bottom 186px
    &-item
      width 180px
      height 234px
      border-radius 20px
      background #fff
      backdrop-filter blur(90px)
      position relative
      text-align center
      margin-left 18px
      // border 1.6px solid #F2C3A1B0
      border 2px solid rgba(112, 52, 34, 0.2)
      &:nth-child(1)
        marigin-left 0
      &.active
        // background linear-gradient(334.35deg, #FFC2B0 -26.03%, #FFF3EF 107.54%)
        // border 1.6px solid #ED7061
        background: linear-gradient(321.82deg, #FFE093 5.61%, #FFEBB5 34.88%, #FFECB9 96.2%);

        .days
          color rgba(136, 55, 0, 1)
          // background linear-gradient(270deg, #615F66 0%, #222124 100%)
          // background-clip text
          // -webkit-background-clip text
          // -webkit-text-fill-color transparent
        .price
          // color rgba(172, 98, 0, 0.8)
          color rgba(136, 55, 0, 1)

          .origin
            color rgba(172, 98, 0, 0.5)
        .day-price
          color rgba(136, 55, 0, 1)
          // background linear-gradient(270deg, #E53700 0%, #FF4D00 100%)
          // background-clip text
          // -webkit-background-clip text
          // -webkit-text-fill-color transparent
      .tips
        padding 0 10px
        height 40px
        line-height 40px
        font-size 20px
        color #fff
        position absolute
        left 0
        top -20px
        background linear-gradient(90deg, #FF3D6B 0%, #8C1AFF 100%)
        border-radius 10px 10px 10px 0
        &.orange
          background linear-gradient(90deg, #ff3d3d 0%, #ff881a 100%)
      .days
        // background linear-gradient(270deg, #625F66 0%, #222124 100%)
        // background-clip text
        // -webkit-background-clip text
        // -webkit-text-fill-color transparent
        color rgba(221, 153, 73, 1)
        font-size 26px
        font-weight 700
        margin 39px 0 3px
      .price
        // color #883700
        color rgba(112, 52, 34, 1)
        font-size 22px
        font-weight 300
        .origin
          // color rgba(172, 98, 0, 0.5)
          color rgba(112, 52, 34, 0.5)
          text-decoration line-through
          margin-left 8px
      .day-price
        // color rgba(136, 55, 0, 1)
        color rgba(221, 153, 73, 1)
        font-size 22px
        font-weight 500
        margin 2px 0
        span
          font-size 46px
          font-weight 700
          font-family 'DIN Alternate', sans-serif
      .pkg-limit-time
        width 100%
        height 36px
        font-size 22px
        display flex
        align-items center
        justify-content center
        position absolute
        bottom 0px
        left 0
        border-radius 0 0 12px 12px
        color rgba(255, 255, 255, 1)
        background rgba(243, 63, 63, 1)
  .divider
    position absolute
    width 2px
    height 233px
    background-color rgba(220, 163, 79, 0.1)
    left 850px
    bottom 224px
  .pay-info
    display flex
    justify-content center
    flex-direction column
    align-items center
    position absolute
    right 51px
    // bottom 226px
    bottom 206px
    .left
      width 176px
      text-align center
      .price
        // color rgba(136, 55, 0, 1)
        color rgba(112, 52, 34, 1)
        font-size 22px
        span
          font-size 40px !important
          line-height 40px
      h3
        color rgba(0, 0, 0, 0.8)
        font-size 22px
        margin-bottom 6px
      p
        color rgba(0, 0, 0, 0.6)
        font-weight 400
        font-size 18px
    .code
      width 160px
      height 160px
      background #fff
      border-radius 5px
      margin-top 8px
      img
        width 146px
        height 146px
        margin 7px
</style>
