import store from "@/store";
import store2 from 'store2'
import { stringify, parse } from "query-string";
import md5 from "crypto-js/md5";
import get from "lodash/get";
import appConfig from "@/config"

export default function getRequestCommonParams(salt = "1111111111", handler) {
  const searchObj = parse(location.search);
  const hashObj = parse(location.hash)
  const _src = get(searchObj, '_src', '') || get(hashObj, '_src', '') || appConfig.src;
  const _vc = appConfig.vc;
  const _vn = appConfig.vn;
  const commonParams = {
    _m: store.state.systemInfo.model || "", // undefined时传空
    _mac: store.state.macAddress,
    _manuf: store.state.systemInfo.brand,
    _p: store.state.systemInfo.system.includes("Android") ? 0 : 1,
    _src,
    _session: store2('_boot_id'),
    _ts: Date.now(),
    _vc,
    _vn,
    ktv_id: "",
    t: Date.now() / 1000, 
    
  };
  let queryStringStr = stringify(commonParams);
  if (typeof handler === "function") {
    queryStringStr = handler(queryStringStr);
  }
  const cipertext = md5(salt + queryStringStr);
  return {
    ...commonParams,
    _unionid: store.state.userInfo.unionid || '',
    _car_name: '', // 车型名称
    _s: cipertext.toString(),
  };
}
