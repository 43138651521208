import httpV2 from '@/utils/httpV2';
import get from 'lodash/get';

/**
 * 活动中心页
 */
export async function getActCenterList() {
  const res = await httpV2.get('/stb/v2/task_center/list')
  return {
    data: get(res, 'data.list', []),
    online: get(res, 'data.online', 0),
  };
}
export async function getOpenscreenInfo() {
  const res = await httpV2.get('/stb/v2/operationV2')
  return get(res, 'data', {});
}
