<template>
  <div :class="className">
    <div class="bg">
      <img
        :class="`${className}-cover`"
        :src="singerHead"
        v-img-fallback="imgFallback"
      />
    </div>
    <p>{{ singerName }}</p>
  </div>
</template>
<script>
import { computed, toRefs } from 'vue'

export default {
  props: {
    singer: {
      type: Object,
      default() {
        return {
          musiccount: 0,
          singerheader: '',
          singerhead: '',
          singerid: 0,
          singerjp: '',
          singer: '',
          singername: '',
          singerqp: '',
          singertypename: '',
        }
      },
    },
    className: {
      type: String,
      default: 'singer-item',
    },
  },
  name: 'SingerItem',
  setup(props) {
    const { singer } = toRefs(props)

    // const imgFallback = {
    //   loading: 'https://qncweb.ktvsky.com/20241129/other/5293f38039c91d3d049687533c2155ad.png',
    //   error: 'https://qncweb.ktvsky.com/20241129/other/5293f38039c91d3d049687533c2155ad.png'
    // }
    const imgFallback = {
      loading:
        'https://qncweb.ktvsky.com/20250221/vadd/a98957de9f0e927b429e1165d5287b35.png',
      error:
        'https://qncweb.ktvsky.com/20250221/vadd/a98957de9f0e927b429e1165d5287b35.png',
    }

    const singerHead = computed(
      () => singer.value.singerheader || singer.value.singerhead
    )
    const singerName = computed(
      () => singer.value.singername || singer.value.singer
    )

    return {
      singerName,
      singerHead,
      imgFallback,
    }
  },
}
</script>
<style lang="stylus" scoped>
.singer-item
  width 216px
  margin-bottom 60px
  .bg
    background url('https://qncweb.ktvsky.com/20250221/vadd/a98957de9f0e927b429e1165d5287b35.png') no-repeat // ('https://qncweb.ktvsky.com/20241129/other/5293f38039c91d3d049687533c2155ad.png') no-repeat
    background-size 100%
    width 100%
    height fit-content
    margin-bottom 20px
  &-cover
    width 100%
    height 100%
    border-radius 50%
  p
    width 100%
    font-size 32px
    color rgba(29, 29, 31, 1)
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
    text-align center
  @media screen and (max-width 1200px) and (min-height 1200px)
    width 200px
    p
      width 200px
    .singer-item-cover
      width 200px
      height 200px
</style>
