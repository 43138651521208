<template>
  <CommonModal
    ref="modalRoot"
    class="new-vip-modal-outer"
    :onCancel="handleModalCancel"
  >
    <div class="vip-modal-content" :class="fromType">
      <div @click="closeModal" class="close-button">
        <svg
          width="96"
          height="96"
          viewBox="0 0 96 96"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_129_1090)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M30.0955 28.2079L28.2099 30.0935L46.124 48.0075L28.2111 65.9204L30.0967 67.806L48.0096 49.8932L65.9223 67.8058L67.8079 65.9202L49.8952 48.0075L67.8091 30.0937L65.9235 28.2081L48.0096 46.1219L30.0955 28.2079Z"
              fill="white"
              fill-opacity="0.3"
            />
          </g>
          <path
            d="M95.0051 48C95.0051 73.9601 73.9602 95.0049 48.0001 95.0049C22.0399 95.0049 0.995066 73.9601 0.995066 48C0.995066 22.0399 22.0399 0.995066 48.0001 0.995066C73.9602 0.995066 95.0051 22.0399 95.0051 48Z"
            stroke="white"
            stroke-opacity="0.3"
            stroke-width="1.99013"
          />
          <defs>
            <clipPath id="clip0_129_1090">
              <rect
                width="53.3334"
                height="53.3333"
                fill="white"
                transform="translate(21.3438 21.3408)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="qr-code-wrapper">
        <img :src="qrCodeURL" alt="VIP QR Code" />
      </div>
    </div>
  </CommonModal>
</template>

<script setup>
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
import useRetainModal from '@/composables/useRetainModal'
import { vipLogFrom } from '@/constants/index'
import { sendLog } from '@/directives/v-log/log'
import { payLogFrom } from '@/log/pay'
import { getCarplayInfo } from '@/service/carplay-info'
import store from '@/store'
import { getRetainModalTag, setRetainModalTag } from '@/utils/historyCache'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import { computed, defineProps, onBeforeMount, ref, toRefs } from 'vue'

const props = defineProps({
  songid: {
    type: Number,
    default: 0,
  },
  log: {
    type: String,
    default: '',
  },
  fromType: {
    type: String,
    default: '',
  },
  logInfo: {
    type: Object,
    default: () => {},
  },
  closeCallback: {
    type: Function,
    default: null,
  },
})

const { songid, log, logInfo, closeCallback } = toRefs(props)
// const isLogin = computed(() => !!store.state.userInfo.unionid)
const { getQRCodeURL } = useQRCode()
const modalRoot = ref(null)
let qrCodeURL = ref(
  'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png' // 新的二维码链接
)

const userType = computed(() => store.state.userInfo.userType)
const { showRetainModal } = useRetainModal()

const fetchVipQRCode = async () => {
  const { data } = await getCarplayInfo()
  if (get(data, 'pay_qr', '')) {
    const logName = log.value ? log.value : '其他'
    let logEvent = null
    if (payLogFrom.has(logName)) {
      logEvent = payLogFrom.get(logName).m
    } else if (vipLogFrom.has(logName)) {
      logEvent = vipLogFrom.get(logName)
    }
    const qrCodeData = await getQRCodeURL(
      `${data.pay_qr}&songid=${
        songid.value ? songid.value : ''
      }&log=${logEvent}&ut=${userType.value}`
    )
    if (qrCodeData) {
      qrCodeURL.value = qrCodeData
    }
    if (songid.value) {
      sendLog({
        event_type: '10000~50000',
        event_name: 30171,
        event_data: {
          str1: '已登录点VIP歌（非VIP）',
          str2: '弹窗',
          str3: '二维码展示',
          str4: 'show',
        },
      })
      if (logInfo.value?.event_name) {
        sendLog({
          event_type: '10000~50000',
          event_name: logInfo.value.event_name,
          event_data: logInfo.value.event_data,
        })
      }
    }
    return
  }
  Toast('未获取到登录二维码')
}

const closeModal = () => {
  store.dispatch('getCarplayInfo')
  modalRoot.value.hide()
  if (log.value !== '开屏弹窗2') {
    if (!getRetainModalTag()) {
      showRetainModal()
      setRetainModalTag()
    }
  }
  sendLog({
    event_type: '10000~50000',
    event_name: 10094,
    event_data: {
      str1: '任意页',
      str2: 'VIP弹窗',
      str3: '关闭弹窗',
      str4: 'click',
    },
  })
  closeCallback.value && closeCallback.value()
}

const handleModalCancel = () => {
  store.dispatch('getCarplayInfo')
  sendLog({
    event_type: '10000~50000',
    event_name: 10094,
    event_data: {
      str1: '任意页',
      str2: 'VIP弹窗',
      str3: '关闭弹窗',
      str4: 'click',
    },
  })
  closeCallback.value && closeCallback.value()
}

onBeforeMount(fetchVipQRCode)
</script>

<style lang="stylus" scoped>
.new-vip-modal-outer
  align-items normal
  @media screen and (max-width 1200px) and (min-height 1200px)
    align-items center
.vip-modal
  &-content
    position relative
    width 1140px
    height 923px
    padding-top 0 !important
    background url(https://qncweb.ktvsky.com/20250225/vadd/c5fb9b7e162b082dd5187aae4acf2c4a.png) no-repeat
    background-size 100% 100%
    background-position center
    @media screen and (max-width 1200px) and (min-height 1200px)
      zoom 0.8

    .close-button
      position absolute
      // top 25px
      // right 30px
      left unset!important
      // width 40px
      // height 40px
      top: 80px
      right: -225px
      width: 96px
      height: 96px
      img
        width: 100%;
        height: 100%;
      @media screen and (max-width 1200px) and (min-height 1200px)
        right: -125px

    .qr-code-wrapper
      position absolute
      bottom 270px !important
      right 153px
      width 210px
      height 210px
      display flex
      justify-content center
      align-items center
      background #FFFFFF
      border-radius 10px
      img
        width: 200px
        height: 200px
      @media screen and (max-width 1200px) and (min-height 1200px)
        // bottom 54px !important

    &-txt
      width 226px
      height 49px
      position absolute
      bottom -42px
      left -18px
      background linear-gradient(90deg, #FF3D3D 0%, #FF881A 100%)
      font-size 24px
      color #fff
      text-align center
      line-height 49px
      border-radius 30px
      padding-left 10px

    .tip
      font-size 32px
      text-align center
      display flex
      flex-direction column
      align-items center
      span
        display flex
        align-items center
      img
        width 45px
        height 45px
        margin-right 10px

    &.vipHighQuality
      // background-image url(https://qncweb.ktvsky.com/20250225/vadd/c5fb9b7e162b082dd5187aae4acf2c4a.png)!important
      // .close-button
      //   right 40px

    &.newSongRecommond
      // background-image url(https://qncweb.ktvsky.com/20240325/vadd/be5340d5b16723d6d55edad3b24e6fe4.png)!important
      // .close-button
      //   right 40px

    &.climax
      // width 1000px
      // height 828px
      // background-image url(https://qncweb.ktvsky.com/20240415/other/b4dd3c59771890e2c4e988e014445542.png)!important
      // .close-button
      //   top 120px
      // .qr-code-wrapper
      //   margin-bottom 25px
      //   width 210px
      //   height 210px
      //   background none
      //   border-radius 5px
      //   @media screen and (max-width: 1200px)
      //     margin-bottom 10px
</style>
